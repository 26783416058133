import { ref, reactive } from 'vue';
import { getCurrentInstance } from 'vue';
import { ElMessage } from 'element-plus';
export default {
  data: function data() {
    return {};
  },
  setup: function setup() {
    var userinfo = reactive({
      data: {}
    });

    var _getCurrentInstance = getCurrentInstance(),
        proxy = _getCurrentInstance.proxy; // 获取用户信息


    var getUserinfo = function getUserinfo() {
      proxy.$http.post('userpage', {
        id: sessionStorage.getItem("id")
      }, {
        headers: {
          'token': sessionStorage.getItem("token")
        }
      }).then(function (res) {
        if (res.data.status == 200) {
          userinfo.data = res.data.data.data;
        } else {
          ElMessage({
            message: res.data.msg,
            type: 'error'
          });
        }
      });
    };

    getUserinfo(); // 修改密码

    var ispwd = ref(true);

    var openPwd = function openPwd() {
      ispwd.value = false;
    };

    var ClosePwd = function ClosePwd() {
      ispwd.value = true;
    };

    var password = ref();

    var confrimPwd = function confrimPwd() {
      proxy.$http.post('updateuser', {
        id: sessionStorage.getItem("id"),
        password: password.value
      }, {
        headers: {
          'token': sessionStorage.getItem("token")
        }
      }).then(function (res) {
        if (res.data.status == 200) {
          ispwd.value = true;
          ElMessage({
            message: res.data.msg,
            type: 'success'
          });
        } else {
          ElMessage({
            message: res.data.msg,
            type: 'error'
          });
        }
      });
    };

    return {
      userinfo: userinfo,
      ispwd: ispwd,
      openPwd: openPwd,
      ClosePwd: ClosePwd,
      confrimPwd: confrimPwd,
      password: password
    };
  }
};