import { useRouter } from "vue-router";
import zhCn from 'element-plus/lib/locale/lang/zh-cn';
import { ref, reactive } from 'vue'; // import * as echarts from 'echarts';

import { getCurrentInstance } from 'vue';
import { ElMessage } from 'element-plus';
import moment from "moment";
export default {
  data: function data() {
    return {
      locale: zhCn,
      size: "small"
    };
  },
  setup: function setup() {
    var router = useRouter();
    var apiTabledata = reactive({
      arr: []
    }); // router.currentRoute.value.query.apiname+

    var titlename = ref('数据统计');

    var toBack = function toBack() {
      router.push({
        path: '/all/apilist'
      });
    };

    var _getCurrentInstance = getCurrentInstance(),
        proxy = _getCurrentInstance.proxy; // 每日对账


    var dayData = reactive({
      arr: []
    }); // let getdayData=function(){
    //     var date1 = moment(moment(new Date()).format("YYYY-MM-DD")+" 00:00:00").unix()
    //     var date2 = moment(moment(new Date()).format("YYYY-MM-DD")+" 23:59:59").unix()
    //     var time=date1+"-"+date2
    //     proxy.$http.post('apiblackstatistics',{admin_user_id:sessionStorage.getItem("id"),
    //     admin_user_api_id:sessionStorage.getItem("apiid"),time:time},{
    //         headers:{
    //             'token':sessionStorage.getItem("token")
    //         }
    //     }).then((res)=>{
    //         if(res.data.status==200){
    //             dayData.arr.push(res.data.data.statistics)
    //         }else{
    //             ElMessage({
    //                 message: res.data.msg,
    //                 type: 'error',
    //             })
    //         }
    //     })
    // }
    // getdayData()

    var toExplain = function toExplain() {
      window.open('https://apifox.com/apidoc/shared-194f8047-a007-40b9-bf9d-ad8f4aa09807', '_blank');
    }; // 总数据


    var totalData = reactive({
      data: {}
    });

    var getTotalData = function getTotalData() {
      var date1 = moment(moment(new Date()).format("YYYY-MM-DD") + " 00:00:00").unix();
      var date2 = moment(moment(new Date()).format("YYYY-MM-DD") + " 23:59:59").unix();
      var time = date1 + "-" + date2;
      proxy.$http.post('apittsstatistics', {
        admin_user_id: sessionStorage.getItem("id"),
        admin_user_api_id: sessionStorage.getItem("apiid"),
        time: time
      }, {
        headers: {
          'token': sessionStorage.getItem("token")
        }
      }).then(function (res) {
        if (res.data.status == 200) {
          totalData.data = res.data.data;
          dayData.arr = res.data.data.statistics;
        } else {
          ElMessage({
            message: res.data.msg,
            type: 'error'
          });
        }
      });
    };

    getTotalData(); // 接口详情
    // let apiid=sessionStorage.getItem("apiid")

    var getapiData = function getapiData() {
      proxy.$http.post('adminuserapipage', {
        id: sessionStorage.getItem("apiid")
      }, {
        headers: {
          'token': sessionStorage.getItem("token")
        }
      }).then(function (res) {
        if (res.data.status == 200) {
          apiTabledata.arr.push(res.data.data.data);
          sessionStorage.setItem("apikey", res.data.data.data.apikey);
        } else {
          ElMessage({
            message: res.data.msg,
            type: 'error'
          });
        }
      });
    };

    getapiData(); // 统计表格
    // let tableData = reactive([])
    // let getTableData= async () =>{
    //     tableData.push(
    //         // {
    //         //     date: '2016-05-03',
    //         //     name: 'Tom',
    //         //     num: '30',
    //         //     inum:"10",
    //         //     idec:"30%"
    //         // }
    //         )
    // }
    // 图表
    // const getCharts = () => {
    //     let myChart = echarts.init(document.getElementById('mycharts'));
    //     myChart.setOption({
    //         xAxis: {
    //             data: ["4-3", "4-4", "4-5", "4-6", "4-7", "4-8", "4-9"]
    //         },
    //         yAxis:{},
    //         grid: {top:'10%',left:"3%",right:"3%"},
    //         series: [
    //             {
    //                 name: "用户量",
    //                 type: "line",
    //                 itemStyle:{
    //                     normal: {
    //                         lineStyle: {
    //                             width:3,
    //                             color:"#409EFC"
    //                         }
    //                     }
    //                 },
    //                 data: [8, 15, 31, 13, 15, 22, 11]
    //             }
    //         ]
    //     });
    // };
    // 充值

    var toRecharge = function toRecharge() {
      router.push({
        path: '/all/apirecharge',
        query: {
          apiname: router.currentRoute.value.query.apiname,
          apiid: sessionStorage.getItem("czid")
        }
      });
    }; // onMounted(()=>{
    //     getTableData()
    //     getCharts()
    // })


    return {
      titlename: titlename,
      toBack: toBack,
      toExplain: toExplain,
      // tableData,getTableData,getCharts,
      getapiData: getapiData,
      apiTabledata: apiTabledata,
      totalData: totalData,
      dayData: dayData,
      toRecharge: toRecharge
    };
  }
};