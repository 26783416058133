import { useRouter } from "vue-router";
import { reactive, ref, computed } from 'vue';
import { ElMessage } from 'element-plus';
import vueQr from 'vue-qr/src/packages/vue-qr.vue';
import { getCurrentInstance } from 'vue';
import moment from "moment";
export default {
  data: function data() {
    return {
      size: "300"
    };
  },
  components: {
    vueQr: vueQr
  },
  setup: function setup() {
    var router = useRouter();
    var rechargeName = ref(router.currentRoute.value.query.apiname);
    var rechargeid = ref(router.currentRoute.value.query.apiid);
    var orderData = reactive({
      data: {}
    });
    var rechargeInfo = reactive({
      data: {
        id: "",
        yxdate: "",
        singlePrice: "",
        yxdateUnit: "",
        trade: "",
        zyTitle: "",
        amount: "",
        discount: "",
        systemTime: ""
      }
    });

    var _getCurrentInstance = getCurrentInstance(),
        proxy = _getCurrentInstance.proxy;

    var checked = ref(false);
    var formData = reactive({
      data: [{
        api_id: "",
        title: "",
        unit_price: "",
        discount: "",
        total_no: "",
        total_price: "",
        term_validity: "",
        term_validity_unit: "m",
        describes: "",
        sort: ""
      }]
    }); // 过滤器

    var changeDatetext = computed(function () {
      return function (date) {
        var text = "";

        if (date == "d") {
          text = "日";
        }

        if (date == "m") {
          text = "个月";
        }

        if (date == "y") {
          text = "年";
        }

        return text;
      };
    }); // 创建订单

    var codeVisible = ref(false);
    var codeUrl = ref();

    var toPay = function toPay(value) {
      if (checked.value) {
        proxy.$http.post('addorder', {
          admin_user_id: sessionStorage.getItem("id"),
          api_set_meal_id: rechargeInfo.data.id,
          order_amount: rechargeInfo.data.amount,
          pay_way: value,
          user_remark: ""
        }, {
          headers: {
            'token': sessionStorage.getItem("token")
          }
        }).then(function (res) {
          if (res.data.status == 200) {
            codeVisible.value = true;
            codeUrl.value = res.data.data.result_pay;
          } else {
            ElMessage({
              message: res.data.msg,
              type: 'error'
            });
          }
        });
      } else {
        ElMessage({
          message: "请先阅读并同意协议",
          type: 'error'
        });
      }
    };

    var price = reactive({}); // 套餐信息

    var getRechargeInfo = function getRechargeInfo() {
      // price.oneprice=0.02
      // price.apiprice=price.oneprice *formData.data.date*formData.data.size/12
      // systemTime =moment().add(formData.data.date, 'month').format('YYYY-MM-DD')
      proxy.$http.post('setmeallist', {
        pagesize: 200,
        api_id: rechargeid.value
      }, {
        headers: {
          'token': sessionStorage.getItem("token")
        }
      }).then(function (res) {
        if (res.data.status == 200) {
          formData.data = res.data.data.data;
          rechargeInfo.data.yxdate = res.data.data.data[0].term_validity;
          rechargeInfo.data.singlePrice = res.data.data.data[0].unit_price;
          rechargeInfo.data.yxdateUnit = res.data.data.data[0].term_validity_unit;
          rechargeInfo.data.trade = 0;
          rechargeInfo.data.zyTitle = res.data.data.data[0].title;
          rechargeInfo.data.amount = res.data.data.data[0].total_price;
          rechargeInfo.data.discount = parseFloat(res.data.data.data[0].discount * 10);
          rechargeInfo.data.id = formData.data[0].id;

          if (formData.data[0].term_validity_unit == "m") {
            rechargeInfo.data.systemTime = moment().add(formData.data[0].term_validity, 'month').format('YYYY-MM-DD');
          } else if (formData.data[0].term_validity_unit == "d") {
            rechargeInfo.data.systemTime = moment().add(formData.data[0].term_validity, 'day').format('YYYY-MM-DD');
          } else if (formData.data[0].term_validity_unit == "y") {
            rechargeInfo.data.systemTime = moment().add(formData.data[0].term_validity, 'year').format('YYYY-MM-DD');
          }
        } else {
          ElMessage({
            message: res.data.msg,
            type: 'error'
          });
        }
      });
    };

    getRechargeInfo(); // 日期

    var systemTime = ref();
    var nowDate = new Date();
    var date = reactive({
      year: nowDate.getFullYear(),
      month: nowDate.getMonth() + 1,
      date: nowDate.getDate()
    });

    if (parseInt(date.date) < 10) {
      date.date = '0' + date.date;
    }

    if (parseInt(date.month) < 10) {
      date.month = '0' + date.month;
    }

    var changeDate = function changeDate(value) {
      systemTime = moment().add(value, 'month').format('YYYY-MM-DD');
      price.apiprice = price.oneprice * value * formData.data.size / 12;
    }; // 购买数量和金额


    var changeSize = function changeSize(value) {
      rechargeInfo.data.id = formData.data[value].id;
      rechargeInfo.data.zyTitle = formData.data[value].title;
      rechargeInfo.data.yxdate = formData.data[value].term_validity;
      rechargeInfo.data.yxdateUnit = formData.data[value].term_validity_unit;
      rechargeInfo.data.amount = formData.data[value].total_price;
      rechargeInfo.data.singlePrice = formData.data[value].unit_price;
      rechargeInfo.data.discount = parseFloat(formData.data[value].discount * 10);

      if (formData.data[value].term_validity_unit == "m") {
        rechargeInfo.data.systemTime = moment().add(formData.data[value].term_validity, 'month').format('YYYY-MM-DD');
      } else if (formData.data[value].term_validity_unit == "d") {
        rechargeInfo.data.systemTime = moment().add(formData.data[value].term_validity, 'day').format('YYYY-MM-DD');
      } else if (formData.data[value].term_validity_unit == "y") {
        rechargeInfo.data.systemTime = moment().add(formData.data[value].term_validity, 'year').format('YYYY-MM-DD');
      }
    };

    var num = ref(1); // let handleNumChange=function(value){
    //     price.apiprice=price.oneprice *value
    //     price.totalprice=price.oneprice *value
    // }

    var toBack = function toBack() {
      router.push({
        path: '/all/apilist'
      });
    };

    return {
      changeDatetext: changeDatetext,
      formData: formData,
      changeSize: changeSize,
      changeDate: changeDate,
      rechargeInfo: rechargeInfo,
      rechargeName: rechargeName,
      num: num,
      checked: checked,
      getRechargeInfo: getRechargeInfo,
      price: price,
      systemTime: systemTime,
      toBack: toBack,
      orderData: orderData,
      toPay: toPay,
      codeVisible: codeVisible,
      codeUrl: codeUrl
    };
  }
};