import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './assets/style.css'
import moment from 'moment'
import axios from 'axios'
import common from './common/common.js'

// import * as echarts from 'echarts';
import 'element-plus/theme-chalk/el-loading.css'
import 'element-plus/theme-chalk/el-message.css'


import 'element-plus/theme-chalk/el-message-box.css'

// import 'element-plus/es/components/message-box/style'



// import zhCn from 'element-plus/es/locale/lang/zh-cn';
// import 'element-plus/dist/index.css'

import * as ElIcon from '@element-plus/icons-vue'
const app = createApp(App)
axios.defaults.baseURL = "https://fxsj1.13524.net/api/"
// app.provide('$axios', axios)

// 全局变量
// 配置全局限制方法
app.config.globalProperties.$noMulClicks = common.noMulClicks
app.config.globalProperties.$dowloadUrl="https://fxsj1.13524.net/"
app.config.globalProperties.$http =axios
// app.use(naive).use(store).use(router).mount('#app')

for (let iconName in ElIcon){
    app.component(iconName, ElIcon[iconName])
}
app.config.globalProperties.$moment = moment
// app.config.globalProperties.echarts = echarts;
// app.user(moment)
app.use(router);
app.mount('#app')

// createApp(App).use(router).mount('#app')
