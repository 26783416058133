import { reactive } from 'vue-demi';
export default {
  data: function data() {
    return {};
  },
  setup: function setup() {
    var formData = reactive({
      data: {}
    });
    return {
      formData: formData
    };
  }
};