import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
import zhCn from 'element-plus/lib/locale/lang/zh-cn';
import { ElMessage, ElMessageBox } from 'element-plus';
import { reactive, ref } from 'vue';
import { getCurrentInstance } from 'vue';
export default {
  data: function data() {
    return {
      locale: zhCn
    };
  },
  setup: function setup() {
    var formData = reactive({
      data: {
        phones: "",
        search: ""
      }
    });
    var activeName = ref('first');
    var tableData1 = reactive({
      arr: []
    });
    var tableData2 = reactive({
      arr: []
    });
    var pageinfo = reactive({
      page: 1,
      pagesize: 10,
      total: ""
    });
    var title = ref();
    var addVisible = ref();
    var type = ref();

    var _getCurrentInstance = getCurrentInstance(),
        proxy = _getCurrentInstance.proxy; // 添加


    var addOpen = function addOpen(value) {
      type.value = value;

      if (value == 2) {
        title = "添加黑名单";
      } else if (value == 1) {
        title = "添加白名单";
      }

      formData.data.phones = "";
      addVisible.value = true;
    };

    var toSumbit = function toSumbit() {
      proxy.$http.post('addblackbwroster', {
        phones: formData.data.phones,
        type: type.value,
        admin_user_id: sessionStorage.getItem("id"),
        admin_user_api_id: sessionStorage.getItem("apiid")
      }, {
        headers: {
          'token': sessionStorage.getItem("token")
        }
      }).then(function (res) {
        if (res.data.status == 200) {
          addVisible.value = false;
          ElMessage({
            message: res.data.msg,
            type: 'success'
          });
          getTabledata(type.value);
        } else {
          ElMessage({
            message: res.data.msg,
            type: 'error'
          });
        }
      });
    }; // 切换tabs


    var tabIndex = ref(0);

    var handleClick = function handleClick(tab) {
      tabIndex.value = tab;

      if (tab.index == 0) {
        getTabledata(2);
      } else if (tab.index == 1) {
        getTabledata(1);
      }
    }; // 搜索


    var toSearch = function toSearch() {
      if (tabIndex.value == 0) {
        getTabledata(2);
      } else if (tabIndex.value == 1) {
        getTabledata(1);
      }
    }; // 列表


    var getTabledata = function getTabledata(val) {
      proxy.$http.post('blackbwrosterlist', {
        page: pageinfo.page,
        pagesize: pageinfo.pagesize,
        type: val,
        search: formData.data.search,
        admin_user_id: sessionStorage.getItem("id"),
        admin_user_api_id: sessionStorage.getItem("apiid")
      }, {
        headers: {
          'token': sessionStorage.getItem("token")
        }
      }).then(function (res) {
        if (res.data.status == 200) {
          if (val == 1) {
            tableData1.arr = res.data.data.data;
          } else if (val == 2) {
            tableData2.arr = res.data.data.data;
          }

          pageinfo['total'] = res.data.data.total;
        }
      });
    };

    getTabledata(2);
    var deldisabled = ref(false); // 删除

    var delData = function delData(ids) {
      ElMessageBox.confirm('确认删除数据吗？', '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        proxy.$http.post('delblackbwroster', {
          ids: ids
        }, {
          headers: {
            'token': sessionStorage.getItem("token")
          }
        }).then(function (res) {
          if (res.data.status == 200) {
            deldisabled.value = true;
            ElMessage({
              message: res.data.msg,
              type: 'success'
            });
            getTabledata(2);
            getTabledata(1);
          } else {
            ElMessage({
              message: res.data.msg,
              type: 'error'
            });
          }
        });
      }).catch(function () {});
    }; // 多选


    var multipleSelection = ref([]);

    var handleSelectionChange = function handleSelectionChange(val) {
      multipleSelection.value = val;

      if (multipleSelection.value.length > 0) {
        deldisabled.value = false;
      } else {
        deldisabled.value = true;
      }
    }; // 分页


    var handleSizeChange1 = function handleSizeChange1(val) {
      pageinfo['pagesize'] = val;
      getTabledata(2);
    };

    var handleCurrentChange1 = function handleCurrentChange1(val) {
      pageinfo['page'] = val;
      getTabledata(2);
    };

    var handleSizeChange2 = function handleSizeChange2(val) {
      pageinfo['pagesize'] = val;
      getTabledata(1);
    };

    var handleCurrentChange2 = function handleCurrentChange2(val) {
      pageinfo['page'] = val;
      getTabledata(1);
    };

    return {
      tableData1: tableData1,
      tableData2: tableData2,
      getTabledata: getTabledata,
      pageinfo: pageinfo,
      formData: formData,
      addOpen: addOpen,
      title: title,
      addVisible: addVisible,
      toSumbit: toSumbit,
      multipleSelection: multipleSelection,
      handleSelectionChange: handleSelectionChange,
      deldisabled: deldisabled,
      delData: delData,
      activeName: activeName,
      handleClick: handleClick,
      toSearch: toSearch,
      handleSizeChange1: handleSizeChange1,
      handleCurrentChange1: handleCurrentChange1,
      handleSizeChange2: handleSizeChange2,
      handleCurrentChange2: handleCurrentChange2
    };
  }
};