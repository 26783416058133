import zhCn from 'element-plus/lib/locale/lang/zh-cn'; // import { ElMessage ,ElMessageBox} from 'element-plus'

import { reactive, ref } from 'vue';
import { getCurrentInstance } from 'vue';
export default {
  data: function data() {
    return {
      locale: zhCn,
      size: "small"
    };
  },
  setup: function setup() {
    var tableData = reactive({
      arr: []
    });
    var deldisabled = ref(false);
    var addVisible = ref(false);
    var pageinfo = reactive({
      page: 1,
      pagesize: 10,
      total: ""
    });
    var formData = reactive({
      data: {}
    });
    var dowloadUrl = ref(getCurrentInstance().appContext.config.globalProperties.$dowloadUrl);

    var _getCurrentInstance = getCurrentInstance(),
        proxy = _getCurrentInstance.proxy; // 获取appid
    // let toSearch=function(){
    //     getvosurl()
    // }
    // let apiid=ref()


    var getvosurl = function getvosurl() {
      //  proxy.$http.post('spacelog',{apikey:sessionStorage.getItem("apikey"),mch_id:sessionStorage.getItem("mch_id")},{
      //     headers:{
      //         'token':sessionStorage.getItem("token")
      //     }
      // }).then((res)=>{
      //     if(res.data.status==200){
      // apiid.value=res.data.data.apiid
      //  var getTabledata=function(){
      proxy.$http.post('spacelog', {
        page: pageinfo.page,
        pagesize: pageinfo.pagesize,
        mch_id: sessionStorage.getItem("mch_id"),
        apikey: sessionStorage.getItem("apikey")
      }, {
        headers: {
          'token': sessionStorage.getItem("token")
        }
      }).then(function (res) {
        if (res.data.status == 200) {
          tableData.arr = res.data.data.data;
          pageinfo['total'] = res.data.data.total;
        }
      }); // }
      // getTabledata()
    }; //     })
    // }


    getvosurl(); // 导出
    // 添加、编辑

    var toSumbit = function toSumbit() {}; // 多选


    var multipleSelection = ref([]);

    var handleSelectionChange = function handleSelectionChange(val) {
      multipleSelection.value = val;

      if (multipleSelection.value.length > 0) {
        deldisabled.value = false;
      } else {
        deldisabled.value = true;
      }
    };

    return {
      dowloadUrl: dowloadUrl,
      toSumbit: toSumbit,
      addVisible: addVisible,
      formData: formData,
      multipleSelection: multipleSelection,
      handleSelectionChange: handleSelectionChange,
      deldisabled: deldisabled,
      tableData: tableData
    };
  }
};