import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.array.splice.js";
import zhCn from 'element-plus/lib/locale/lang/zh-cn';
import { reactive } from 'vue';
import { getCurrentInstance } from 'vue';
import { ElMessage } from 'element-plus';
export default {
  data: function data() {
    return {
      locale: zhCn
    };
  },
  setup: function setup() {
    var _getCurrentInstance = getCurrentInstance(),
        proxy = _getCurrentInstance.proxy;

    var formData = reactive({
      data: {
        tels: [{
          phone: ""
        }],
        balance_reminder_no: "",
        balance_reminder_switch: 1
      }
    }); // 详情

    var getDeatil = function getDeatil() {
      proxy.$http.post('adminuserapisetpage', {
        admin_user_api_id: sessionStorage.getItem("apiid")
      }, {
        headers: {
          'token': sessionStorage.getItem("token")
        }
      }).then(function (res) {
        if (res.data.status == 200) {
          formData.data = res.data.data.data;
          formData.data.tels = [];
          var arr = res.data.data.data.telephones.split(",");

          for (var i in arr) {
            formData.data.tels.push({
              phone: arr[i]
            });
          }
        } else {
          ElMessage({
            message: res.data.msg,
            type: 'error'
          });
        }
      });
    };

    getDeatil(); // 编辑

    var onSubmit = function onSubmit() {
      var telephones = "";

      if (formData.data.tels.length == 1) {
        telephones = formData.data.tels[0].phone;
      } else if (formData.data.tels.length == 0) {
        telephones = "";
      } else {
        for (var i in formData.data.tels) {
          telephones = formData.data.tels[i].phone + "," + telephones;
          console.log(telephones);
        }

        telephones = telephones.replace(/[,]$/, "");
      }

      proxy.$http.post('addadminuserapiset', {
        admin_user_id: sessionStorage.getItem("id"),
        admin_user_api_id: sessionStorage.getItem("apiid"),
        balance_reminder_no: formData.data.balance_reminder_no,
        balance_reminder_switch: formData.data.balance_reminder_switch,
        telephones: telephones
      }, {
        headers: {
          'token': sessionStorage.getItem("token")
        }
      }).then(function (res) {
        if (res.data.status == 200) {
          ElMessage({
            message: res.data.msg,
            type: 'success'
          });
        } else {
          ElMessage({
            message: res.data.msg,
            type: 'error'
          });
        }
      });
    };

    var addTel = function addTel() {
      formData.data.tels.push({
        phone: ""
      });
    };

    var removeDomain = function removeDomain(index) {
      formData.data.tels.splice(index, 1);
    };

    return {
      addTel: addTel,
      removeDomain: removeDomain,
      formData: formData,
      onSubmit: onSubmit
    };
  }
};