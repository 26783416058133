import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.array.join.js";
import zhCn from 'element-plus/lib/locale/lang/zh-cn';
import { reactive, ref, getCurrentInstance } from 'vue';
import { ElMessage, ElMessageBox } from 'element-plus';
export default {
  data: function data() {
    return {
      locale: zhCn,
      value1: "",
      size: 'small'
    };
  },
  setup: function setup() {
    var cztitle = ref();
    var manageRef = ref(null);
    var formData = reactive({
      data: {
        pid: "",
        title: "",
        href: "",
        menu_status: 1,
        icon: "",
        sort: ""
      }
    });
    var tableData = reactive({
      arr: []
    });

    var _getCurrentInstance = getCurrentInstance(),
        proxy = _getCurrentInstance.proxy;

    var getTabledata = function getTabledata() {
      proxy.$http.post('nodelist', {}, {
        headers: {
          'token': sessionStorage.getItem("token")
        }
      }).then(function (res) {
        if (res.data.status == 200) {
          tableData.arr = res.data.data;
        }
      });
    };

    getTabledata(); // 编辑用户

    var openEdit = function openEdit(id) {
      proxy.$http.post('nodepage', {
        id: id
      }, {
        headers: {
          'token': sessionStorage.getItem("token")
        }
      }).then(function (res) {
        if (res.data.status == 200) {
          cztitle.value = "编辑节点";
          addVisible.value = true;
          formData.data = res.data.data.data;
        } else {
          ElMessage({
            message: res.data.msg,
            type: 'error'
          });
        }
      });
    }; // 添加用户


    var addVisible = ref(false);

    var openAdd = function openAdd(pid) {
      formData.data = {
        pid: "",
        title: "",
        href: "",
        menu_status: 1,
        icon: "",
        sort: ""
      };
      formData.data.pid = pid;
      cztitle.value = "添加节点";
      addVisible.value = true;
    }; // 提交


    var toSumbit = function toSumbit() {
      if (cztitle.value == "添加节点") {
        proxy.$http.post('addnode', {
          pid: formData.data.pid,
          title: formData.data.title,
          href: formData.data.href,
          menu_status: formData.data.menu_status,
          icon: formData.data.icon,
          sort: formData.data.sort
        }, {
          headers: {
            'token': sessionStorage.getItem("token")
          }
        }).then(function (res) {
          if (res.data.status == 200) {
            addVisible.value = false;
            ElMessage({
              message: res.data.msg,
              type: 'success'
            });
            manageRef.value.resetFields();
            getTabledata();
          } else {
            ElMessage({
              message: res.data.msg,
              type: 'error'
            });
          }
        });
      } else if (cztitle.value == "编辑节点") {
        proxy.$http.post('updatenode', {
          id: formData.data.id,
          pid: formData.data.pid,
          title: formData.data.title,
          href: formData.data.href,
          menu_status: formData.data.menu_status,
          icon: formData.data.icon,
          sort: formData.data.sort
        }, {
          headers: {
            'token': sessionStorage.getItem("token")
          }
        }).then(function (res) {
          if (res.data.status == 200) {
            addVisible.value = false;
            ElMessage({
              message: res.data.msg,
              type: 'success'
            });
            manageRef.value.resetFields();
            getTabledata();
          } else {
            ElMessage({
              message: res.data.msg,
              type: 'error'
            });
          }
        });
      }
    }; // 多选


    var multipleSelection = ref([]);

    var handleSelectionChange = function handleSelectionChange(val) {
      multipleSelection.value = val;

      if (multipleSelection.value.length > 0) {
        deldisabled.value = false;
      } else {
        deldisabled.value = true;
      }
    }; // 删除


    var deldisabled = ref(true);
    var arr = [];

    var delMore = function delMore() {
      arr = [];
      var ids = "";

      if (multipleSelection.value.length == 1) {
        ids = multipleSelection.value[0].id;
      } else {
        for (var i in multipleSelection.value) {
          arr.push(multipleSelection.value[i].id);
        }

        ids = arr.join(",");
      }

      delData(ids);
    };

    var delData = function delData(ids) {
      ElMessageBox.confirm('确认删除数据吗？删除父节点子节点一并删除!', '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        proxy.$http.post('delnode', {
          ids: ids
        }, {
          headers: {
            'token': sessionStorage.getItem("token")
          }
        }).then(function (res) {
          if (res.data.status == 200) {
            deldisabled.value = true;
            ElMessage({
              message: res.data.msg,
              type: 'success'
            });
            getTabledata();
          } else {
            ElMessage({
              message: res.data.msg,
              type: 'error'
            });
          }
        });
      }).catch(function () {});
    };

    return {
      getTabledata: getTabledata,
      tableData: tableData,
      multipleSelection: multipleSelection,
      handleSelectionChange: handleSelectionChange,
      cztitle: cztitle,
      formData: formData,
      manageRef: manageRef,
      addVisible: addVisible,
      openAdd: openAdd,
      openEdit: openEdit,
      toSumbit: toSumbit,
      deldisabled: deldisabled,
      delMore: delMore,
      delData: delData
    };
  }
};