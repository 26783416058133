// import {DataAnalysis,User,Setting,Wallet,ArrowDown,UserFilled} from '@element-plus/icons-vue'
import { useRouter } from "vue-router";
import { reactive, ref } from 'vue';
import { ElMessage } from 'element-plus';
import { getCurrentInstance } from 'vue';
export default {
  // components:{
  //   DataAnalysis,User,Setting,Wallet,ArrowDown,UserFilled
  // },
  setup: function setup() {
    var menuIcon = ref("Fold");
    var router = useRouter();
    var username = ref();
    var menulist = reactive({
      arr: []
    });
    username.value = sessionStorage.getItem("username");

    var _getCurrentInstance = getCurrentInstance(),
        proxy = _getCurrentInstance.proxy;

    var isCollapse = ref(false);

    var isMenu = function isMenu() {
      if (isCollapse.value) {
        menuIcon.value = "Fold";
      } else {
        menuIcon.value = "Expand";
      }

      isCollapse.value = !isCollapse.value;
    };

    var toIndex = function toIndex() {
      router.push({
        path: "/all/apilist"
      });
    }; // 菜单


    var getMenu = function getMenu() {
      proxy.$http.post('nodelist', {
        menu_status: 1,
        admin_role_id: sessionStorage.getItem("admin_role_id")
      }, {
        headers: {
          'token': sessionStorage.getItem("token")
        }
      }).then(function (res) {
        if (res.data.status == 200) {
          menulist.arr = res.data.data;
        } else {
          ElMessage({
            message: res.data.msg,
            type: 'error'
          });
        }
      });
    };

    getMenu();

    var toPage = function toPage(url) {
      router.push({
        path: url
      });
    };

    var toLoginout = function toLoginout() {
      sessionStorage.setItem("token", "");
      router.push({
        path: "/login"
      });
    };

    return {
      isCollapse: isCollapse,
      menuIcon: menuIcon,
      isMenu: isMenu,
      getMenu: getMenu,
      menulist: menulist,
      toPage: toPage,
      username: username,
      toLoginout: toLoginout,
      toIndex: toIndex
    };
  }
};