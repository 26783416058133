import { reactive } from 'vue';
import zhCn from 'element-plus/lib/locale/lang/zh-cn';
import { getCurrentInstance } from 'vue';
import { ElMessage } from 'element-plus';
import * as echarts from 'echarts';
import moment from "moment";
export default {
  data: function data() {
    return {
      locale: zhCn,
      size: "small",
      closable: false
    };
  },
  setup: function setup() {
    var searchData = reactive({
      create_time: ""
    });

    var _getCurrentInstance = getCurrentInstance(),
        proxy = _getCurrentInstance.proxy; // 查询


    var toSearch = function toSearch() {
      getTableData();
    }; // 统计表格


    var tableData = reactive({
      arr: []
    });

    var getTableData = function getTableData() {
      // var hour=moment().hour()
      // var start=""
      // var end=""
      // if(hour>10||hour==10){
      //     start=(hour-10)*10
      //     end=(hour-10)*10+10
      // }else if(hour<10&&hour!=0){
      //     start=(10-hour)*10
      //     hour=(10-hour)*10+10
      // }else if(hour==0){
      //     start=90
      //     end=100
      // }
      var date = "";
      console.log(searchData.create_time);

      if (searchData.create_time) {
        date = moment(searchData.create_time + " 00:00:00").unix() + "-" + moment(searchData.create_time + " 23:59:59").unix();
      }

      proxy.$http.post('ttsphoneslisttimestatistics', {
        create_time: date,
        admin_user_id: sessionStorage.getItem("id"),
        admin_user_api_id: sessionStorage.getItem("apiid")
      }, {
        headers: {
          'token': sessionStorage.getItem("token")
        }
      }).then(function (res) {
        if (res.data.status == 200) {
          var myChart = echarts.init(document.getElementById('mycharts'));
          myChart.setOption({
            title: {
              left: 'center',
              text: '呼叫数量走势图',
              subtext: '鼠标滚轮滚动可以对图表进行缩放查看'
            },
            xAxis: {
              data: res.data.data.date_minute
            },
            yAxis: {},
            dataZoom: [{
              type: "inside",
              start: 0,
              end: 10
            }],
            grid: {
              top: '17%',
              left: "3%",
              right: "3%"
            },
            series: [{
              name: "用户量",
              type: "line",
              itemStyle: {
                normal: {
                  lineStyle: {
                    width: 3,
                    color: "#409EFC"
                  }
                }
              },
              data: res.data.data.call_count
            }]
          });
        } else {
          ElMessage({
            message: res.data.msg,
            type: 'error'
          });
        }
      });
    };

    getTableData();
    return {
      searchData: searchData,
      tableData: tableData,
      toSearch: toSearch
    };
  }
};