import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.url.js";
import "core-js/modules/web.url-search-params.js";
import "core-js/modules/es.array.splice.js";
import { ref } from 'vue';
import { getCurrentInstance } from 'vue';
import { ElMessage } from 'element-plus';
export default {
  data: function data() {
    return {
      noClick: true
    };
  },
  setup: function setup() {
    var dowloadUrl = ref(getCurrentInstance().appContext.config.globalProperties.$dowloadUrl);
    dowloadUrl.value = dowloadUrl.value + "/storage/template/号码质检.txt";

    var _getCurrentInstance = getCurrentInstance(),
        proxy = _getCurrentInstance.proxy; // 获取appid


    var apiid = ref();

    var getvosurl = function getvosurl() {
      proxy.$http.post('blackvosrewritelist', {
        apikey: sessionStorage.getItem("apikey")
      }, {
        headers: {
          'token': sessionStorage.getItem("token")
        }
      }).then(function (res) {
        if (res.data.status == 200) {
          apiid.value = res.data.data.apiid;
          console.log(res);
        }
      });
    };

    getvosurl(); // 下载模板

    var todown = function todown() {
      var request = new XMLHttpRequest();
      request.responseType = "blob";
      var fileUrl = "https://fxsjadmin.13524.net/号码质检.txt"; // 文件路径

      request.open("GET", fileUrl);

      request.onload = function () {
        var url = window.URL.createObjectURL(this.response);
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.href = url;
        a.download = "号码质检";
        a.click();
      };

      request.send();
    }; // 上传表格


    var fullscreenLoading = ref(false);
    var tableFile = ref();

    var beforeTableUpload = function beforeTableUpload(file, fileList) {
      console.log(file);
      tableFile.value = fileList[0].raw;

      if (fileList.length > 1) {
        fileList.splice(0, 1);
      }
    };

    var uploadtable = function uploadtable() {
      fullscreenLoading.value = true;
      var formData = new FormData();
      formData.append("mch_id", sessionStorage.getItem("mch_id"));
      formData.append("file", tableFile.value);
      proxy.$http.post('spacechecktxt?apiid=' + apiid.value, {
        "mch_id": sessionStorage.getItem("mch_id"),
        apikey: sessionStorage.getItem("apikey"),
        "file": tableFile.value
      }, {
        headers: {
          "Content-Type": "multipart/form-data",
          'token': sessionStorage.getItem("token")
        }
      }).then(function (res) {
        if (res.data.status == 200) {
          fullscreenLoading.value = false;
          ElMessage({
            message: res.data.msg,
            type: 'success'
          });
        } else {
          fullscreenLoading.value = false;
          ElMessage({
            message: res.data.msg,
            type: 'error'
          });
        }
      });
    };

    return {
      todown: todown,
      beforeTableUpload: beforeTableUpload,
      fullscreenLoading: fullscreenLoading,
      uploadtable: uploadtable,
      dowloadUrl: dowloadUrl
    };
  }
};