import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.url.js";
import "core-js/modules/web.url-search-params.js";
import { reactive, ref, nextTick } from 'vue';
import zhCn from 'element-plus/lib/locale/lang/zh-cn';
import { getCurrentInstance } from 'vue';
import { ElMessage } from 'element-plus'; // import moment from "moment";

export default {
  data: function data() {
    return {
      locale: zhCn,
      size: "small",
      closable: false,
      labelPosition: 'left',
      noClick: true
    };
  },
  setup: function setup() {
    var _getCurrentInstance = getCurrentInstance(),
        proxy = _getCurrentInstance.proxy;

    var synthesisList = reactive([{
      id: "14",
      img: "female",
      title: "瑞琳",
      desc: "标准女声",
      voice: "ruilin"
    }, {
      id: "1",
      img: "female",
      title: "艾琪",
      desc: "温柔女声",
      voice: "aiqi"
    }, {
      id: "2",
      img: "male",
      title: "艾诚",
      desc: "标准男声",
      voice: "aicheng"
    }, {
      id: "3",
      img: "female",
      title: "艾佳",
      desc: "标准女声",
      voice: "aijia"
    }, {
      id: "4",
      img: "female",
      title: "思琪",
      desc: "温柔女声",
      voice: "siqi"
    }, {
      id: "5",
      img: "female",
      title: "思佳",
      desc: "标准女声",
      voice: "sijia"
    }, {
      id: "6",
      img: "male",
      title: "马树",
      desc: "儿童剧男声",
      voice: "mashu"
    }, {
      id: "7",
      img: "female",
      title: "悦儿",
      desc: "儿童剧女声",
      voice: "yuer"
    }, {
      id: "8",
      img: "female",
      title: "若兮",
      desc: "温柔女声",
      voice: "ruoxi"
    }, {
      id: "9",
      img: "male",
      title: "艾达",
      desc: "标准男声",
      voice: "aida"
    }, {
      id: "10",
      img: "male",
      title: "思诚",
      desc: "标准女声",
      voice: "sicheng"
    }, {
      id: "11",
      img: "female",
      title: "宁儿",
      desc: "标准女声",
      voice: "ninger"
    }, {
      id: "12",
      img: "female",
      title: "小云",
      desc: "标准女声",
      voice: "xiaoyun"
    }, {
      id: "13",
      img: "male",
      title: "小刚",
      desc: "标准男声",
      voice: "xiaogang"
    }]);
    var current = ref("14");
    var formData = reactive({
      text: "您好，欢迎使用飞象智能语音服务平台，我们坚信技术是一种力量，秉承知行合一，止于至善的理念，推动智能语音事业的发展。",
      speech_rate: 38,
      pitch_rate: 0,
      volume: 60,
      sample_rate: "8000",
      format: "wav",
      voice: "ruilin",
      title: ""
    }); // 播放录音

    var isplay = ref(true);
    var currentInstance = getCurrentInstance();
    var videoSrc = ref();

    var toPlay = function toPlay() {
      isplay.value = false;
      console.log(formData.text);
      proxy.$http.post('ttsaivoice', {
        admin_user_id: sessionStorage.getItem("id"),
        admin_user_api_id: sessionStorage.getItem("apiid"),
        text: formData.text,
        pitch_rate: formData.pitch_rate,
        volume: formData.volume,
        sample_rate: formData.sample_rate,
        format: formData.format,
        voice: formData.voice,
        speech_rate: formData.speech_rate
      }, {
        headers: {
          'token': sessionStorage.getItem("token")
        }
      }).then(function (res) {
        if (res.data.status == 200) {
          isplay.value = true;
          videoSrc.value = res.data.data.path_voice;
          nextTick(function () {
            var audioPlay = currentInstance.proxy.$refs.audio;
            audioPlay.play();
          }); // console.log("2222")
        } else {
          ElMessage({
            message: res.data.msg,
            type: 'error'
          });
        }
      });
    };

    var isdown = ref(true);

    var toDown = function toDown() {
      if (videoSrc.value && videoSrc.value != undefined) {
        var downloadElement = document.createElement('a'); // 创建下载的链接
        // let href = window.URL.createObjectURL(res.data.result.href)

        downloadElement.style.display = 'none';
        downloadElement.href = videoSrc.value; // 下载后文件名
        // downloadElement.download = fileName

        document.body.appendChild(downloadElement); // 点击下载

        downloadElement.click(); // 下载完成移除元素

        document.body.removeChild(downloadElement); // 释放掉blob对象

        window.URL.revokeObjectURL(videoSrc.value);
      } else {
        isdown.value = false;
        proxy.$http.post('ttsaivoice', {
          admin_user_id: sessionStorage.getItem("id"),
          admin_user_api_id: sessionStorage.getItem("apiid"),
          text: formData.text,
          pitch_rate: formData.pitch_rate,
          volume: formData.volume,
          sample_rate: formData.sample_rate,
          format: formData.format,
          voice: formData.voice
        }, {
          headers: {
            'token': sessionStorage.getItem("token")
          }
        }).then(function (res) {
          if (res.data.status == 200) {
            isdown.value = true;
            videoSrc.value = res.data.data.path_voice;

            var _downloadElement = document.createElement('a'); // 创建下载的链接
            // let href = window.URL.createObjectURL(res.data.result.href)


            _downloadElement.style.display = 'none';
            _downloadElement.href = videoSrc.value; // 下载后文件名
            // downloadElement.download = fileName

            document.body.appendChild(_downloadElement); // 点击下载

            _downloadElement.click(); // 下载完成移除元素


            document.body.removeChild(_downloadElement); // 释放掉blob对象

            window.URL.revokeObjectURL(videoSrc.value);
          } else {
            ElMessage({
              message: res.data.msg,
              type: 'error'
            });
          }
        });
      }
    }; // 保存


    var fullscreenLoading = ref(false);

    var toSave = function toSave() {
      fullscreenLoading.value = true;

      if (videoSrc.value && videoSrc.value != undefined) {
        saveFunction(videoSrc.value);
      } else {
        proxy.$http.post('ttsaivoice', {
          admin_user_id: sessionStorage.getItem("id"),
          admin_user_api_id: sessionStorage.getItem("apiid"),
          text: formData.text,
          pitch_rate: formData.pitch_rate,
          volume: formData.volume,
          sample_rate: formData.sample_rate,
          format: formData.format,
          voice: formData.voice,
          speech_rate: formData.speech_rate
        }, {
          headers: {
            'token': sessionStorage.getItem("token")
          }
        }).then(function (res) {
          if (res.data.status == 200) {
            saveFunction(res.data.data.path_voice);
          } else {
            fullscreenLoading.value = false;
            ElMessage({
              message: res.data.msg,
              type: 'error'
            });
          }
        });
      }
    };

    var saveFunction = function saveFunction(videopath) {
      proxy.$http.post('addttsaivoicenoticeurl', {
        admin_user_id: sessionStorage.getItem("id"),
        admin_user_api_id: sessionStorage.getItem("apiid"),
        url_file: videopath,
        title: formData.title,
        text: formData.text
      }, {
        headers: {
          "Content-Type": "multipart/form-data",
          'token': sessionStorage.getItem("token")
        }
      }).then(function (res) {
        if (res.data.status == 200) {
          fullscreenLoading.value = false;
          ElMessage({
            message: res.data.msg,
            type: 'success'
          });
        } else {
          fullscreenLoading.value = false;
          ElMessage({
            message: res.data.msg,
            type: 'error'
          });
        }
      });
    }; // 选择声音


    var videoName = ref("瑞琳");

    var toSlectVideo = function toSlectVideo(id, name, voice) {
      formData.text = "您好，欢迎使用飞象智能语音服务平台，我们坚信技术是一种力量，秉承知行合一，止于至善的理念，推动智能语音事业的发展。", formData.speech_rate = 38, formData.pitch_rate = 0, formData.volume = 60, formData.sample_rate = "8000", formData.format = "wav", formData.voice = "ruilin";
      videoSrc.value = "";
      current.value = id;
      videoName.value = name;
      formData.voice = voice;
    };

    return {
      formData: formData,
      toSave: toSave,
      fullscreenLoading: fullscreenLoading,
      toPlay: toPlay,
      isplay: isplay,
      isdown: isdown,
      videoSrc: videoSrc,
      toDown: toDown,
      synthesisList: synthesisList,
      current: current,
      toSlectVideo: toSlectVideo,
      videoName: videoName
    };
  }
};