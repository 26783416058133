import "core-js/modules/es.array.join.js";
import { reactive, ref } from 'vue';
import zhCn from 'element-plus/lib/locale/lang/zh-cn';
import { getCurrentInstance } from 'vue';
import { ElMessage, ElMessageBox } from 'element-plus'; // import moment from "moment";

export default {
  data: function data() {
    return {
      locale: zhCn,
      size: "small",
      closable: false
    };
  },
  setup: function setup() {
    var pageinfo = reactive({
      page: 1,
      pagesize: 10,
      total: ""
    });
    var searchData = reactive({
      title: ""
    });

    var _getCurrentInstance = getCurrentInstance(),
        proxy = _getCurrentInstance.proxy;

    var formData = reactive({
      data: {
        title: "",
        content: ""
      }
    }); // 查询

    var called_number = ref();

    var toSearch = function toSearch() {
      getTableData();
    }; // 统计表格


    var tableData = reactive({
      arr: []
    });

    var getTableData = function getTableData() {
      proxy.$http.post('variablescriptlist', {
        page: pageinfo.page,
        pagesize: pageinfo.pagesize,
        title: searchData.title,
        admin_user_id: sessionStorage.getItem("id"),
        admin_user_api_id: sessionStorage.getItem("apiid")
      }, {
        headers: {
          'token': sessionStorage.getItem("token")
        }
      }).then(function (res) {
        if (res.data.status == 200) {
          tableData.arr = res.data.data.data;
        } else {
          ElMessage({
            message: res.data.msg,
            type: 'error'
          });
        }
      });
    };

    getTableData();
    var addVisible = ref(false);
    var cztitle = ref("添加变量话术"); // 添加

    var openAdd = function openAdd() {
      formData.data.title = "", formData.data.content = "";
      cztitle.value = "添加变量话术";
      addVisible.value = true;
    }; // 编辑


    var openEdit = function openEdit(id) {
      proxy.$http.post('variablescriptpage', {
        id: id
      }, {
        headers: {
          'token': sessionStorage.getItem("token")
        }
      }).then(function (res) {
        if (res.data.status == 200) {
          cztitle.value = "编辑变量话术";
          addVisible.value = true;
          formData.data = res.data.data.data;
        } else {
          ElMessage({
            message: res.data.msg,
            type: 'error'
          });
        }
      });
    }; // 提交


    var toSumbit = function toSumbit() {
      if (formData.data.password != formData.data.repassword && formData.data.repassword != "") {
        ElMessage({
          message: "两次密码不一致",
          type: 'warning'
        });
        return false;
      } else {
        if (cztitle.value == "添加变量话术") {
          proxy.$http.post('addvariablescript', {
            title: formData.data.title,
            content: formData.data.content,
            admin_user_id: sessionStorage.getItem("id"),
            admin_user_api_id: sessionStorage.getItem("apiid")
          }, {
            headers: {
              'token': sessionStorage.getItem("token")
            }
          }).then(function (res) {
            if (res.data.status == 200) {
              addVisible.value = false;
              ElMessage({
                message: res.data.msg,
                type: 'success'
              });
              getTableData();
            } else {
              ElMessage({
                message: res.data.msg,
                type: 'error'
              });
            }
          });
        } else if (cztitle.value == "编辑变量话术") {
          console.log(formData.data);
          proxy.$http.post('updatevariablescript', {
            id: formData.data.id,
            title: formData.data.title,
            content: formData.data.content
          }, {
            headers: {
              'token': sessionStorage.getItem("token")
            }
          }).then(function (res) {
            if (res.data.status == 200) {
              addVisible.value = false;
              ElMessage({
                message: res.data.msg,
                type: 'success'
              });
              getTableData();
            } else {
              ElMessage({
                message: res.data.msg,
                type: 'error'
              });
            }
          });
        }
      }
    }; // 多选


    var deldisabled = ref(true);
    var multipleSelection = ref([]);

    var handleSelectionChange = function handleSelectionChange(val) {
      multipleSelection.value = val;

      if (multipleSelection.value.length > 0) {
        deldisabled.value = false;
      } else {
        deldisabled.value = true;
      }
    }; // 删除
    // 删除


    var delData = function delData(ids) {
      ElMessageBox.confirm('确认删除数据吗？', '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        proxy.$http.post('delvariablescript', {
          ids: ids
        }, {
          headers: {
            'token': sessionStorage.getItem("token")
          }
        }).then(function (res) {
          if (res.data.status == 200) {
            deldisabled.value = true;
            ElMessage({
              message: res.data.msg,
              type: 'success'
            });
            getTableData();
          } else {
            ElMessage({
              message: res.data.msg,
              type: 'error'
            });
          }
        });
      }).catch(function () {});
    };

    var arr = [];

    var delMore = function delMore() {
      arr = [];
      var ids = "";

      if (multipleSelection.value.length == 1) {
        ids = multipleSelection.value[0].id;
      } else {
        for (var i in multipleSelection.value) {
          arr.push(multipleSelection.value[i].id);
        }

        ids = arr.join(",");
      }

      delData(ids);
    }; // 分页


    var handleSizeChange = function handleSizeChange(val) {
      pageinfo['pagesize'] = val;
      getTableData();
    };

    var handleCurrentChange = function handleCurrentChange(val) {
      pageinfo['page'] = val;
      getTableData();
    };

    return {
      handleSizeChange: handleSizeChange,
      handleCurrentChange: handleCurrentChange,
      pageinfo: pageinfo,
      searchData: searchData,
      addVisible: addVisible,
      cztitle: cztitle,
      openAdd: openAdd,
      delMore: delMore,
      delData: delData,
      openEdit: openEdit,
      formData: formData,
      handleSelectionChange: handleSelectionChange,
      multipleSelection: multipleSelection,
      deldisabled: deldisabled,
      toSumbit: toSumbit,
      tableData: tableData,
      toSearch: toSearch,
      called_number: called_number
    };
  }
};