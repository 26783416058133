import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.array.join.js";
import { reactive, ref } from 'vue';
import zhCn from 'element-plus/lib/locale/lang/zh-cn';
import { getCurrentInstance } from 'vue';
import { ElMessage, ElMessageBox } from 'element-plus';
import { useRouter } from "vue-router"; // import moment from "moment";

export default {
  data: function data() {
    return {
      locale: zhCn,
      size: "small",
      closable: false
    };
  },
  setup: function setup() {
    var router = useRouter();
    var pageinfo = reactive({
      page: 1,
      pagesize: 10,
      total: ""
    });
    var searchData = reactive({
      title: ""
    });

    var _getCurrentInstance = getCurrentInstance(),
        proxy = _getCurrentInstance.proxy;

    var formData = reactive({
      data: {
        title: "",
        content: ""
      }
    }); // 查询

    var called_number = ref();

    var toSearch = function toSearch() {
      getTableData();
    }; // 统计表格


    var tableData = reactive({
      arr: []
    });

    var getTableData = function getTableData() {
      proxy.$http.post('ttsaivoicenoticelist', {
        page: pageinfo.page,
        pagesize: pageinfo.pagesize,
        title: searchData.title,
        admin_user_id: sessionStorage.getItem("id"),
        admin_user_api_id: sessionStorage.getItem("apiid")
      }, {
        headers: {
          'token': sessionStorage.getItem("token")
        }
      }).then(function (res) {
        if (res.data.status == 200) {
          tableData.arr = res.data.data.data;
        } else {
          ElMessage({
            message: res.data.msg,
            type: 'error'
          });
        }
      });
    };

    getTableData();
    var addVisible = ref(false);
    var cztitle = ref("添加合成话术"); // 添加

    var openAdd = function openAdd() {
      formData.data.title = "", formData.data.text = "", tableFile.value = "";
      cztitle.value = "添加合成话术";
      addVisible.value = true;
    }; // 编辑


    var openEdit = function openEdit(id) {
      proxy.$http.post('ttsaivoicenoticepage', {
        id: id
      }, {
        headers: {
          'token': sessionStorage.getItem("token")
        }
      }).then(function (res) {
        if (res.data.status == 200) {
          cztitle.value = "编辑合成话术";
          addVisible.value = true;
          formData.data = res.data.data.data;
        } else {
          ElMessage({
            message: res.data.msg,
            type: 'error'
          });
        }
      });
    }; // 导入


    var tableFile = ref();
    var fullscreenLoading = ref(false);

    var beforeTableUpload = function beforeTableUpload(file, fileList) {
      console.log(file);
      tableFile.value = fileList[0].raw;

      if (fileList.length > 1) {
        fileList.splice(0, 1);
      }
    }; // 提交


    var toSumbit = function toSumbit() {
      if (cztitle.value == "添加合成话术") {
        fullscreenLoading.value = true;
        proxy.$http.post('addttsaivoicenotice', {
          admin_user_id: sessionStorage.getItem("id"),
          admin_user_api_id: sessionStorage.getItem("apiid"),
          "file": tableFile.value,
          title: formData.data.title,
          text: formData.data.text
        }, {
          headers: {
            "Content-Type": "multipart/form-data",
            'token': sessionStorage.getItem("token")
          }
        }).then(function (res) {
          if (res.data.status == 200) {
            addVisible.value = false;
            getTableData();
            ElMessage({
              message: res.data.msg,
              type: 'success'
            });
          } else {
            ElMessage({
              message: res.data.msg,
              type: 'error'
            });
          }
        });
      } else if (cztitle.value == "编辑合成话术") {
        fullscreenLoading.value = true;
        proxy.$http.post('updatettsaivoicenotice', {
          id: formData.data.id,
          "file": tableFile.value,
          title: formData.data.title,
          text: formData.data.text
        }, {
          headers: {
            "Content-Type": "multipart/form-data",
            'token': sessionStorage.getItem("token")
          }
        }).then(function (res) {
          if (res.data.status == 200) {
            addVisible.value = false;
            getTableData();
            ElMessage({
              message: res.data.msg,
              type: 'success'
            });
          } else {
            ElMessage({
              message: res.data.msg,
              type: 'error'
            });
          }
        });
      }
    }; // 多选


    var deldisabled = ref(true);
    var multipleSelection = ref([]);

    var handleSelectionChange = function handleSelectionChange(val) {
      console.log(multipleSelection.value);
      multipleSelection.value = val;

      if (multipleSelection.value.length > 0) {
        deldisabled.value = false;
      } else {
        deldisabled.value = true;
      }
    }; // 删除
    // 删除


    var delData = function delData(ids) {
      ElMessageBox.confirm('确认删除数据吗？', '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        proxy.$http.post('delttsaivoicenotice', {
          ids: ids
        }, {
          headers: {
            'token': sessionStorage.getItem("token")
          }
        }).then(function (res) {
          if (res.data.status == 200) {
            deldisabled.value = true;
            ElMessage({
              message: res.data.msg,
              type: 'success'
            });
            getTableData();
          } else {
            ElMessage({
              message: res.data.msg,
              type: 'error'
            });
          }
        });
      }).catch(function () {});
    };

    var arr = [];

    var delMore = function delMore() {
      arr = [];
      var ids = "";

      if (multipleSelection.value.length == 1) {
        ids = multipleSelection.value[0].id;
      } else {
        for (var i in multipleSelection.value) {
          arr.push(multipleSelection.value[i].id);
        }

        ids = arr.join(",");
      }

      delData(ids);
    };

    var toSynthesis = function toSynthesis() {
      router.push({
        path: "/cloud/synthesis"
      });
    }; // 分页


    var handleSizeChange = function handleSizeChange(val) {
      pageinfo['pagesize'] = val;
      getTableData();
    };

    var handleCurrentChange = function handleCurrentChange(val) {
      pageinfo['page'] = val;
      getTableData();
    };

    return {
      beforeTableUpload: beforeTableUpload,
      fullscreenLoading: fullscreenLoading,
      toSynthesis: toSynthesis,
      handleSizeChange: handleSizeChange,
      handleCurrentChange: handleCurrentChange,
      pageinfo: pageinfo,
      searchData: searchData,
      addVisible: addVisible,
      cztitle: cztitle,
      openAdd: openAdd,
      delMore: delMore,
      delData: delData,
      openEdit: openEdit,
      formData: formData,
      handleSelectionChange: handleSelectionChange,
      multipleSelection: multipleSelection,
      deldisabled: deldisabled,
      toSumbit: toSumbit,
      tableData: tableData,
      toSearch: toSearch,
      called_number: called_number
    };
  }
};