import "core-js/modules/es.json.stringify.js";
import { useRouter } from "vue-router";
import { reactive, ref } from 'vue';
import { ElMessage } from 'element-plus';
import { getCurrentInstance } from 'vue';
export default {
  data: function data() {
    return {
      labelPosition: 'top',
      size: "small"
    };
  },
  setup: function setup() {
    var router = useRouter();

    var _getCurrentInstance = getCurrentInstance(),
        proxy = _getCurrentInstance.proxy;

    var requestText = ref();
    var respondText = ref();
    var testName = ref(router.currentRoute.value.query.apiname + '接口测试');
    var tableData = reactive([{
      title: "mch_id",
      type: "string",
      ismust: "是",
      desc: "说明：商户id 示例值：fx16600343006515"
    }, {
      title: "call_number",
      type: "string",
      ismust: "否",
      desc: "说明：主叫号码"
    }, {
      title: "hash_type",
      type: "string",
      ismust: "是",
      desc: "说明：被叫号 码加密 类型 1:不加密 2:sha256 加密 示例值：1"
    }, {
      title: "called_number",
      type: "string",
      ismust: "是",
      desc: "说明：被叫号码"
    }, {
      title: "sign",
      type: "string",
      ismust: "是",
      desc: "说明：对所有业务参数，按参数名正序拼接（字典序）， 使用 URL 键值对的格式（即为key1=value1&key2=value2…）拼接成字符串， 最后拼接上【apikey】得到新的字符串，并对新的字符串进行 MD5 运算，得到加密字符串字符串(大写)，即为sign。"
    }]);
    var formData = reactive({
      data: {}
    });

    var toBack = function toBack() {
      router.push({
        path: '/all/apilist'
      });
    };

    var toSubmit = function toSubmit() {
      console.log(formData);
      requestText = JSON.stringify(formData);
      proxy.$http.post('black', {
        mch_id: formData.data.mch_id,
        call_number: formData.data.call_number,
        hash_type: formData.data.hash_type,
        called_number: formData.data.called_number,
        sign: formData.data.sign
      }, {
        headers: {
          'token': sessionStorage.getItem("token")
        }
      }).then(function (res) {
        if (res.data.status == 200) {
          respondText = JSON.stringify(res.data);
        } else {
          ElMessage({
            message: res.data.msg,
            type: 'error'
          });
        }
      });
    }; // 获取数据


    var apiTestdata = reactive({});

    var getTestdata = function getTestdata() {
      apiTestdata = {
        "data": "",
        "name": '/api/black',
        "address": "http://fxsj.13524.net",
        "type": "1"
      };
    };

    getTestdata();
    return {
      tableData: tableData,
      formData: formData,
      toSubmit: toSubmit,
      respondText: respondText,
      requestText: requestText,
      testName: testName,
      toBack: toBack,
      apiTestdata: apiTestdata,
      getTestdata: getTestdata
    };
  }
};