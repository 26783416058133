import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "img-verify"
};
var _hoisted_2 = ["width", "height"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("canvas", {
    ref: "verify",
    width: _ctx.width,
    height: _ctx.height,
    onClick: _cache[0] || (_cache[0] = function () {
      return $setup.handleDraw && $setup.handleDraw.apply($setup, arguments);
    })
  }, null, 8, _hoisted_2)]);
}