import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import { reactive, ref } from 'vue-demi';
import { getCurrentInstance } from 'vue';
import { ElMessage } from 'element-plus';
export default {
  data: function data() {
    return {
      noClick: true
    };
  },
  setup: function setup() {
    var formData = reactive({
      data: {
        starthour: '00',
        startminut: '00',
        endhour: '23',
        endminut: '59',
        urlnotify: "",
        parallel_no: ""
      }
    });
    var hourOptions = reactive([{
      value: "00"
    }, {
      value: "01"
    }, {
      value: "02"
    }, {
      value: "03"
    }, {
      value: "04"
    }, {
      value: "05"
    }, {
      value: "06"
    }, {
      value: "07"
    }, {
      value: "08"
    }, {
      value: "09"
    }, {
      value: "10"
    }, {
      value: "11"
    }, {
      value: "12"
    }, {
      value: "13"
    }, {
      value: "14"
    }, {
      value: "15"
    }, {
      value: "16"
    }, {
      value: "17"
    }, {
      value: "18"
    }, {
      value: "19"
    }, {
      value: "20"
    }, {
      value: "21"
    }, {
      value: "22"
    }, {
      value: "23"
    }]);
    var minuteOptions = reactive([{
      value: "00"
    }, {
      value: "01"
    }, {
      value: "02"
    }, {
      value: "03"
    }, {
      value: "04"
    }, {
      value: "05"
    }, {
      value: "06"
    }, {
      value: "07"
    }, {
      value: "08"
    }, {
      value: "09"
    }, {
      value: "10"
    }, {
      value: "11"
    }, {
      value: "12"
    }, {
      value: "13"
    }, {
      value: "14"
    }, {
      value: "15"
    }, {
      value: "16"
    }, {
      value: "17"
    }, {
      value: "18"
    }, {
      value: "19"
    }, {
      value: "20"
    }, {
      value: "21"
    }, {
      value: "22"
    }, {
      value: "23"
    }, {
      value: "24"
    }, {
      value: "25"
    }, {
      value: "26"
    }, {
      value: "27"
    }, {
      value: "28"
    }, {
      value: "29"
    }, {
      value: "30"
    }, {
      value: "31"
    }, {
      value: "32"
    }, {
      value: "33"
    }, {
      value: "34"
    }, {
      value: "35"
    }, {
      value: "36"
    }, {
      value: "37"
    }, {
      value: "38"
    }, {
      value: "39"
    }, {
      value: "40"
    }, {
      value: "41"
    }, {
      value: "42"
    }, {
      value: "43"
    }, {
      value: "44"
    }, {
      value: "45"
    }, {
      value: "46"
    }, {
      value: "47"
    }, {
      value: "48"
    }, {
      value: "49"
    }, {
      value: "50"
    }, {
      value: "51"
    }, {
      value: "52"
    }, {
      value: "53"
    }, {
      value: "54"
    }, {
      value: "56"
    }, {
      value: "57"
    }, {
      value: "58"
    }, {
      value: "59"
    }]);
    var mch_id = ref(sessionStorage.getItem("mch_id"));
    var apikey = ref(sessionStorage.getItem("apikey"));

    var _getCurrentInstance = getCurrentInstance(),
        proxy = _getCurrentInstance.proxy; // 详情


    var getDetail = function getDetail() {
      proxy.$http.post('adminuserapisetpage', {
        admin_user_api_id: sessionStorage.getItem("apiid")
      }, {
        headers: {
          'token': sessionStorage.getItem("token")
        }
      }).then(function (res) {
        if (res.data.status == 200) {
          formData.data = res.data.data.data;

          if (res.data.data.data.start_call_daily_time) {
            var arr1 = res.data.data.data.start_call_daily_time.split(":");
            formData.data.starthour = arr1[0];
            formData.data.startminut = arr1[1];
          }

          if (res.data.data.data.end_call_daily_time) {
            var arr2 = res.data.data.data.end_call_daily_time.split(":");
            formData.data.endhour = arr2[0];
            formData.data.endminut = arr2[1];
          }
        } else {
          ElMessage({
            message: res.data.msg,
            type: 'error'
          });
        }
      });
    };

    getDetail(); // 修改

    var toSumbit = function toSumbit() {
      var start_call_daily_time = formData.data.starthour + ":" + formData.data.startminut;
      var end_call_daily_time = formData.data.endhour + ":" + formData.data.endminut;
      proxy.$http.post('addadminuserapiset', {
        admin_user_id: sessionStorage.getItem("id"),
        admin_user_api_id: sessionStorage.getItem("apiid"),
        urlnotify: formData.data.urlnotify,
        start_call_daily_time: start_call_daily_time,
        end_call_daily_time: end_call_daily_time
      }, {
        headers: {
          'token': sessionStorage.getItem("token")
        }
      }).then(function (res) {
        if (res.data.status == 200) {
          getDetail();
          ElMessage({
            message: res.data.msg,
            type: 'success'
          });
        } else {
          ElMessage({
            message: res.data.msg,
            type: 'error'
          });
        }
      });
    };

    return {
      formData: formData,
      toSumbit: toSumbit,
      mch_id: mch_id,
      apikey: apikey,
      hourOptions: hourOptions,
      minuteOptions: minuteOptions
    };
  }
};