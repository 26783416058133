import { reactive, ref } from 'vue';
import zhCn from 'element-plus/lib/locale/lang/zh-cn';
import { getCurrentInstance } from 'vue';
import { ElMessage } from 'element-plus';
import moment from "moment";
export default {
  data: function data() {
    return {
      locale: zhCn,
      size: "small",
      closable: false
    };
  },
  setup: function setup() {
    var pageinfo = reactive({
      page: 1,
      pagesize: 10,
      total: ""
    });
    var searchData = reactive({
      create_time: ""
    });

    var _getCurrentInstance = getCurrentInstance(),
        proxy = _getCurrentInstance.proxy; // 查询


    var toSearch = function toSearch() {
      getTableData();
    }; // 统计表格


    var tableData = reactive({
      arr: []
    });

    var getTableData = function getTableData() {
      var date = "";

      if (searchData.create_time && searchData.create_time[0]) {
        date = moment(searchData.create_time[0] + " 00:00:00").unix() + "-" + moment(searchData.create_time[1] + " 23:59:59").unix();
      }

      proxy.$http.post('ttsphonesliststatistics', {
        create_time: date,
        admin_user_id: sessionStorage.getItem("id"),
        admin_user_api_id: sessionStorage.getItem("apiid")
      }, {
        headers: {
          'token': sessionStorage.getItem("token")
        }
      }).then(function (res) {
        if (res.data.status == 200) {
          tableData.arr = res.data.data; // pageinfo['total']=res.data.data.total
        } else {
          ElMessage({
            message: res.data.msg,
            type: 'error'
          });
        }
      });
    };

    getTableData(); // 多选

    var multipleSelection = ref([]);

    var handleSelectionChange = function handleSelectionChange(val) {
      multipleSelection.value = val;
    }; // 分页


    var handleSizeChange = function handleSizeChange(val) {
      pageinfo['pagesize'] = val;
      getTableData();
    };

    var handleCurrentChange = function handleCurrentChange(val) {
      pageinfo['page'] = val;
      getTableData();
    };

    return {
      multipleSelection: multipleSelection,
      handleSelectionChange: handleSelectionChange,
      handleSizeChange: handleSizeChange,
      handleCurrentChange: handleCurrentChange,
      pageinfo: pageinfo,
      tableData: tableData,
      toSearch: toSearch,
      searchData: searchData
    };
  }
};