import { useRouter } from "vue-router";
import { ref, reactive } from 'vue';
import { getCurrentInstance } from 'vue';
import { ElMessage } from 'element-plus';
export default {
  data: function data() {
    return {
      count: 0 // dialogVisible:false

    };
  },
  setup: function setup() {
    var router = useRouter();
    var tableData = reactive({
      arr: []
    });
    var userinfo = reactive({
      data: {}
    });
    var pageinfo = reactive({
      page: 1,
      pagesize: 10,
      total: ""
    });

    var _getCurrentInstance = getCurrentInstance(),
        proxy = _getCurrentInstance.proxy; // 获取用户信息


    var getUserinfo = function getUserinfo() {
      proxy.$http.post('userpage', {
        id: sessionStorage.getItem("id")
      }, {
        headers: {
          'token': sessionStorage.getItem("token")
        }
      }).then(function (res) {
        if (res.data.status == 200) {
          userinfo.data = res.data.data.data;
        } else {
          ElMessage({
            message: res.data.msg,
            type: 'error'
          });
        }
      });
    };

    getUserinfo(); // 号码

    var telinfo = reactive({
      data: {}
    });

    var getDetail = function getDetail() {
      proxy.$http.post('systemsetpage', {
        id: 1
      }, {
        headers: {
          'token': sessionStorage.getItem("token")
        }
      }).then(function (res) {
        if (res.data.status == 200) {
          telinfo.data = res.data.data.data;
        } else {
          ElMessage({
            message: res.data.msg,
            type: 'error'
          });
        }
      });
    };

    getDetail();

    var getTabledata = function getTabledata() {
      proxy.$http.post('apilist', {
        page: pageinfo.page,
        pagesize: pageinfo.pagesize,
        admin_user_id: sessionStorage.getItem("id")
      }, {
        headers: {
          'token': sessionStorage.getItem("token")
        }
      }).then(function (res) {
        if (res.data.status == 200) {
          tableData.arr = res.data.data.data;
          pageinfo['total'] = res.data.data.total;
        } else {
          ElMessage({
            message: res.data.msg,
            type: 'error'
          });
        }
      });
    };

    getTabledata(); // 统计

    var toStatistics = function toStatistics(id, czid, url, title) {
      var href = "/" + url;
      sessionStorage.setItem("apiid", id);
      sessionStorage.setItem("czid", czid);
      router.push({
        path: href,
        query: {
          apiname: title
        }
      });
    }; // 测试


    var toTest = function toTest(id, czid, url) {
      var href = "/" + url + "/blackTest";
      sessionStorage.setItem("apiid", id);
      sessionStorage.setItem("czid", czid);
      router.push({
        path: href,
        query: {
          apiname: '测试'
        }
      });
    };

    var toAlert = function toAlert(id, czid, url) {
      var href = "/" + url + "/reminderSun";
      sessionStorage.setItem("apiid", id);
      sessionStorage.setItem("czid", czid);
      router.push({
        path: href
      });
    }; // 预警


    var dialogVisible = ref(false);
    var alertTitle = ref("预警设置");

    var openAlert = function openAlert(name) {
      alertTitle.value = name + "预警设置";
      dialogVisible.value = true;
    };

    var isalert = ref('1'); // 充值

    var toRecharge = function toRecharge(id, title) {
      router.push({
        path: '/all/apirecharge',
        query: {
          apiname: title,
          apiid: id
        }
      });
    }; // 分页


    var handleSizeChange = function handleSizeChange(val) {
      pageinfo['pagesize'] = val;
      getTabledata();
    };

    var handleCurrentChange = function handleCurrentChange(val) {
      pageinfo['page'] = val;
      getTabledata();
    };

    return {
      userinfo: userinfo,
      telinfo: telinfo,
      toStatistics: toStatistics,
      dialogVisible: dialogVisible,
      alertTitle: alertTitle,
      openAlert: openAlert,
      isalert: isalert,
      toTest: toTest,
      toRecharge: toRecharge,
      toAlert: toAlert,
      getTabledata: getTabledata,
      tableData: tableData,
      pageinfo: pageinfo,
      handleSizeChange: handleSizeChange,
      handleCurrentChange: handleCurrentChange
    };
  }
};