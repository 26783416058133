import { ref } from 'vue';
import { getCurrentInstance } from 'vue';
import { ElMessage } from 'element-plus';
export default {
  data: function data() {
    return {
      noClick: true
    };
  },
  setup: function setup() {
    var _getCurrentInstance = getCurrentInstance(),
        proxy = _getCurrentInstance.proxy;

    var telephones = ref();
    var result = ref();
    var fullscreenLoading = ref(false); // 获取appid

    var toCheck = function toCheck() {
      fullscreenLoading.value = true;
      proxy.$http.post('spacechecktextarea', {
        mch_id: sessionStorage.getItem("mch_id"),
        apikey: sessionStorage.getItem("apikey"),
        telephones: telephones.value
      }, {
        headers: {
          'token': sessionStorage.getItem("token")
        }
      }).then(function (res) {
        if (res.data.status == 200) {
          fullscreenLoading.value = false;
          result.value = res.data.data;
        } else {
          fullscreenLoading.value = false;
          ElMessage({
            message: res.data.msg,
            type: 'error'
          });
        }
      });
    };

    return {
      toCheck: toCheck,
      telephones: telephones,
      result: result
    };
  }
};