import "core-js/modules/es.array.join.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.url.js";
import "core-js/modules/web.url-search-params.js";
import { reactive, ref, computed, nextTick } from 'vue';
import zhCn from 'element-plus/lib/locale/lang/zh-cn';
import { getCurrentInstance } from 'vue';
import { ElMessage } from 'element-plus';
import { formatDate } from "../../utils/filter2.js"; // import moment from "moment";

export default {
  data: function data() {
    return {
      locale: zhCn,
      size: "small",
      closable: false
    };
  },
  setup: function setup() {
    var pageinfo = reactive({
      page: 1,
      pagesize: 10,
      total: ""
    });
    var searchData = reactive({
      phone: "",
      answer_state: ""
    });

    var _getCurrentInstance = getCurrentInstance(),
        proxy = _getCurrentInstance.proxy; // 过滤器


    var changeFormdate = computed(function () {
      return function (time) {
        var date = new Date(time);
        return formatDate(date);
      };
    }); // 查询

    var export3 = ref();
    var export2 = ref();
    var export1 = ref();

    var toSearch = function toSearch() {
      pageinfo.oage = 1;
      getTableData();
    }; // 播放录音


    var currentInstance = getCurrentInstance();
    var videoSrc = ref();

    var toPlay = function toPlay(src) {
      videoSrc.value = "https://fxsj1.13524.net/" + src; // console.log(videoSrc.value)

      var audioPlay = currentInstance.proxy.$refs.audio;
      nextTick(function () {
        audioPlay.play();
      });
    }; // 统计表格


    var tableData = reactive({
      arr: []
    });

    var getTableData = function getTableData() {
      proxy.$http.post('ttscalllog', {
        page: pageinfo.page,
        pagesize: pageinfo.pagesize,
        phone: searchData.phone,
        answer_state: searchData.answer_state,
        admin_user_id: sessionStorage.getItem("id"),
        admin_user_api_id: sessionStorage.getItem("apiid")
      }, {
        headers: {
          'token': sessionStorage.getItem("token")
        }
      }).then(function (res) {
        if (res.data.status == 200) {
          tableData.arr = res.data.data.data;
          pageinfo['total'] = res.data.data.total;
          export2.value = res.data.data.total;
          export1.value = res.data.data.data.length;
        } else {
          ElMessage({
            message: res.data.msg,
            type: 'error'
          });
        }
      });
    };

    getTableData(); // 多选

    var multipleSelection = ref([]);
    export3.value = multipleSelection.value.length;

    var handleSelectionChange = function handleSelectionChange(val) {
      multipleSelection.value = val;
      console.log(multipleSelection.value);
      export3.value = multipleSelection.value.length;
    }; // 导出


    var line = ref();
    var dialogVisible = ref(false);

    var toExport = function toExport() {
      dialogVisible.value = true;
    };

    var exportSelect = function exportSelect() {
      var arr = [];

      if (multipleSelection.value.length == 1) {
        exportid.value = multipleSelection.value[0].id;
      } else {
        for (var i in multipleSelection.value) {
          arr.push(multipleSelection.value[i].id);
        }

        exportid.value = arr.join(",");
      }

      line.value = export3.value;
      exportAllData();
    };

    var exportid = ref();

    var exportPage = function exportPage() {
      var ids = [];
      var data = tableData.arr;

      for (var i in data) {
        ids.push(data[i].id);
      }

      exportid.value = ids.join();
      line.value = export1.value;
      exportAllData();
    };

    var exportAll = function exportAll() {
      line.value = export2.value;
      exportid.value = "";
      exportAllData();
    };

    var exportAllData = function exportAllData() {
      proxy.$http.post('exportttscalllogurltest', {
        ids: exportid.value,
        answer_state: searchData.answer_state,
        phone: searchData.phone,
        rows: line.value,
        admin_user_id: sessionStorage.getItem("id"),
        admin_user_api_id: sessionStorage.getItem("apiid")
      }, {
        headers: {
          'token': sessionStorage.getItem("token")
        }
      }).then(function (res) {
        // console.log(res.data.result.href)
        var downloadElement = document.createElement('a'); // 创建下载的链接
        // let href = window.URL.createObjectURL(res.data.result.href)

        downloadElement.style.display = 'none';
        downloadElement.href = res.data.data.href; // 下载后文件名
        // downloadElement.download = fileName

        document.body.appendChild(downloadElement); // 点击下载

        downloadElement.click(); // 下载完成移除元素

        document.body.removeChild(downloadElement); // 释放掉blob对象

        window.URL.revokeObjectURL(res.data.data.href);
      });
    }; // 分页


    var handleSizeChange = function handleSizeChange(val) {
      pageinfo['pagesize'] = val;
      getTableData();
    };

    var handleCurrentChange = function handleCurrentChange(val) {
      pageinfo['page'] = val;
      getTableData();
    };

    return {
      videoSrc: videoSrc,
      toPlay: toPlay,
      changeFormdate: changeFormdate,
      dialogVisible: dialogVisible,
      toExport: toExport,
      exportSelect: exportSelect,
      exportPage: exportPage,
      exportAll: exportAll,
      exportAllData: exportAllData,
      export1: export1,
      export2: export2,
      export3: export3,
      handleSizeChange: handleSizeChange,
      handleCurrentChange: handleCurrentChange,
      pageinfo: pageinfo,
      handleSelectionChange: handleSelectionChange,
      multipleSelection: multipleSelection,
      tableData: tableData,
      toSearch: toSearch,
      searchData: searchData
    };
  }
};