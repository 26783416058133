import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.url.js";
import "core-js/modules/web.url-search-params.js";
import zhCn from 'element-plus/lib/locale/lang/zh-cn';
import { reactive, ref } from 'vue';
import { getCurrentInstance } from 'vue';
import { ElMessage } from 'element-plus';
export default {
  data: function data() {
    return {
      locale: zhCn,
      closable: false
    };
  },
  setup: function setup() {
    var _getCurrentInstance = getCurrentInstance(),
        proxy = _getCurrentInstance.proxy;

    var activeName = ref('first');
    var formData = reactive({
      remarks: "",
      api_tts_ai_voice_notice_id: ""
    });
    var formData2 = reactive({
      remarks: ""
    });
    var formData3 = reactive({
      remarks: "",
      api_variable_script_id: ""
    });

    var handleClick = function handleClick() {};

    var textList = reactive({
      arr: []
    });

    var getTextlist = function getTextlist() {
      proxy.$http.post('variablescriptlist', {
        page: 1,
        pagesize: 200,
        admin_user_id: sessionStorage.getItem("id"),
        admin_user_api_id: sessionStorage.getItem("apiid")
      }, {
        headers: {
          'token': sessionStorage.getItem("token")
        }
      }).then(function (res) {
        if (res.data.status == 200) {
          textList.arr = res.data.data.data;
        } else {
          ElMessage({
            message: res.data.msg,
            type: 'error'
          });
        }
      });
    };

    getTextlist(); // 语音合成列表

    var tableData = reactive({
      arr: []
    });

    var getTableData = function getTableData() {
      proxy.$http.post('ttsaivoicenoticelist', {
        page: 1,
        pagesize: 200,
        admin_user_id: sessionStorage.getItem("id"),
        admin_user_api_id: sessionStorage.getItem("apiid")
      }, {
        headers: {
          'token': sessionStorage.getItem("token")
        }
      }).then(function (res) {
        if (res.data.status == 200) {
          tableData.arr = res.data.data.data;
        } else {
          ElMessage({
            message: res.data.msg,
            type: 'error'
          });
        }
      });
    };

    getTableData();
    var fullscreenLoading = ref(false); // 号码导入

    var tableFile = ref();

    var beforeTableUpload = function beforeTableUpload(file, fileList) {
      console.log(file);
      tableFile.value = fileList[0].raw;

      if (fileList.length > 1) {
        fileList.splice(0, 1);
      }
    };

    var onUploadTel = function onUploadTel() {
      fullscreenLoading.value = true;
      proxy.$http.post('importphone', {
        admin_user_id: sessionStorage.getItem("id"),
        admin_user_api_id: sessionStorage.getItem("apiid"),
        "file": tableFile.value,
        remarks: formData.remarks,
        api_tts_ai_voice_notice_id: formData.api_tts_ai_voice_notice_id
      }, {
        headers: {
          "Content-Type": "multipart/form-data",
          'token': sessionStorage.getItem("token")
        }
      }).then(function (res) {
        if (res.data.status == 200) {
          fullscreenLoading.value = false;
          formData.remarks = "";
          formData.api_tts_ai_voice_notice_id = "";
          ElMessage({
            message: res.data.msg,
            type: 'success'
          });
        } else {
          fullscreenLoading.value = false;
          ElMessage({
            message: res.data.msg,
            type: 'error'
          });
        }
      });
    }; // 号码+文字导入


    var tableFile2 = ref();

    var beforeTableUpload2 = function beforeTableUpload2(file, fileList) {
      console.log(file);
      tableFile2.value = fileList[0].raw;

      if (fileList.length > 1) {
        fileList.splice(0, 1);
      }
    };

    var onUploadTel2 = function onUploadTel2() {
      fullscreenLoading.value = true;
      proxy.$http.post('importphonetxt', {
        admin_user_id: sessionStorage.getItem("id"),
        admin_user_api_id: sessionStorage.getItem("apiid"),
        "file": tableFile2.value,
        remarks: formData2.remarks
      }, {
        headers: {
          "Content-Type": "multipart/form-data",
          'token': sessionStorage.getItem("token")
        }
      }).then(function (res) {
        if (res.data.status == 200) {
          fullscreenLoading.value = false;
          formData2.remarks = "";
          ElMessage({
            message: res.data.msg,
            type: 'success'
          });
        } else {
          fullscreenLoading.value = false;
          ElMessage({
            message: res.data.msg,
            type: 'error'
          });
        }
      });
    }; // 变量导入


    var tableFile3 = ref();

    var beforeTableUpload3 = function beforeTableUpload3(file, fileList) {
      console.log(file);
      tableFile3.value = fileList[0].raw;

      if (fileList.length > 1) {
        fileList.splice(0, 1);
      }
    };

    var onUploadTel3 = function onUploadTel3() {
      fullscreenLoading.value = true;
      proxy.$http.post('importphonevartxt', {
        api_variable_script_id: formData3.api_variable_script_id,
        admin_user_id: sessionStorage.getItem("id"),
        admin_user_api_id: sessionStorage.getItem("apiid"),
        "file": tableFile3.value,
        remarks: formData3.remarks
      }, {
        headers: {
          "Content-Type": "multipart/form-data",
          'token': sessionStorage.getItem("token")
        }
      }).then(function (res) {
        if (res.data.status == 200) {
          fullscreenLoading.value = false;
          formData3.remarks = "";
          ElMessage({
            message: res.data.msg,
            type: 'success'
          });
        } else {
          fullscreenLoading.value = false;
          ElMessage({
            message: res.data.msg,
            type: 'error'
          });
        }
      });
    }; // 下载


    var toDownload = function toDownload() {
      if (formData3.api_variable_script_id == "") {
        ElMessage({
          message: "请选择话术",
          type: 'error'
        });
      } else {
        proxy.$http.post('exportvariablescripturl', {
          api_variable_script_id: formData3.api_variable_script_id,
          token: sessionStorage.getItem("token")
        }, {
          headers: {
            'token': sessionStorage.getItem("token")
          }
        }).then(function (res) {
          if (res.data.status == 200) {
            var downloadElement = document.createElement('a'); // 创建下载的链接
            // let href = window.URL.createObjectURL(res.data.result.href)

            downloadElement.style.display = 'none';
            downloadElement.href = res.data.data.href; // 下载后文件名
            // downloadElement.download = fileName

            document.body.appendChild(downloadElement); // 点击下载

            downloadElement.click(); // 下载完成移除元素

            document.body.removeChild(downloadElement); // 释放掉blob对象

            window.URL.revokeObjectURL(res.data.data.href);
          } else {
            ElMessage({
              message: res.data.msg,
              type: 'error'
            });
          }
        });
      }
    };

    return {
      toDownload: toDownload,
      tableData: tableData,
      getTableData: getTableData,
      formData: formData,
      textList: textList,
      formData2: formData2,
      formData3: formData3,
      beforeTableUpload: beforeTableUpload,
      beforeTableUpload2: beforeTableUpload2,
      beforeTableUpload3: beforeTableUpload3,
      activeName: activeName,
      handleClick: handleClick,
      fullscreenLoading: fullscreenLoading,
      onUploadTel: onUploadTel,
      onUploadTel2: onUploadTel2,
      onUploadTel3: onUploadTel3
    };
  }
};