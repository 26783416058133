import "core-js/modules/es.array.join.js";
import zhCn from 'element-plus/lib/locale/lang/zh-cn';
import { reactive, ref, computed } from 'vue';
import { ElMessage, ElMessageBox } from 'element-plus'; // import moment from "moment";

import { formatDate } from "../../utils/filter.js";
import { getCurrentInstance } from 'vue'; // import { inject } from 'vue'

export default {
  data: function data() {
    return {
      locale: zhCn,
      value1: "",
      size: 'small'
    };
  },
  setup: function setup() {
    var cztitle = ref();
    var manageRef = ref(null);
    var searchData = reactive({
      username: "",
      company: "",
      create_time: ""
    });
    var formData = reactive({
      data: {
        title: "",
        tag: ""
      }
    });
    var pageinfo = reactive({
      page: 1,
      pagesize: 10,
      total: ""
    });
    var tableData = reactive({
      arr: []
    });

    var _getCurrentInstance = getCurrentInstance(),
        proxy = _getCurrentInstance.proxy; // 过滤器


    var changeFormdate = computed(function () {
      return function (time) {
        var date = new Date(time);
        return formatDate(date);
      };
    }); // 查询

    var defaultTime = ref([new Date(2000, 1, 1, 0, 0, 0), new Date(2000, 2, 1, 23, 59, 59)]);

    var toSearch = function toSearch() {
      getTabledata();
    };

    var getTabledata = function getTabledata() {
      // var date=""
      // if(searchData.create_time[0]){
      //     console.log(searchData.create_time[0])
      //     date=moment.unix(searchData.create_time[0])/1000000+"-"+moment.unix(searchData.create_time[1])/1000000
      // }
      proxy.$http.post('apiinterfacelist', {
        page: pageinfo.page,
        pagesize: pageinfo.pagesize
      }, {
        headers: {
          'token': sessionStorage.getItem("token")
        }
      }).then(function (res) {
        if (res.data.status == 200) {
          tableData.arr = res.data.data.data;
          pageinfo['total'] = res.data.data.total;
        } else {
          ElMessage({
            message: res.data.msg,
            type: 'error'
          });
        }
      });
    };

    getTabledata(); // 日期

    var nowDate = new Date();
    var date = reactive({
      year: nowDate.getFullYear(),
      month: nowDate.getMonth() + 1,
      date: nowDate.getDate()
    });

    if (parseInt(date.date) < 10) {
      date.date = '0' + date.date;
    }

    if (parseInt(date.month) < 10) {
      date.month = '0' + date.month;
    }

    console.log(formData.data.starttime); // 添加接口

    var addVisible = ref(false);

    var openAdd = function openAdd() {
      formData.data = {
        title: "",
        tag: ""
      };
      formData.data.starttime = date.year + "-" + date.month + "-" + date.date;
      cztitle.value = "添加接口";
      addVisible.value = true;
    }; // 编辑接口
    // 接口详情


    var openEdit = function openEdit(id) {
      proxy.$http.post('apiinterfacepage', {
        id: id
      }, {
        headers: {
          'token': sessionStorage.getItem("token")
        }
      }).then(function (res) {
        if (res.data.status == 200) {
          cztitle.value = "编辑接口";
          addVisible.value = true;
          formData.data = res.data.data.data;
        } else {
          ElMessage({
            message: res.data.msg,
            type: 'error'
          });
        }
      });
    }; // 提交


    var toSumbit = function toSumbit() {
      if (cztitle.value == "添加接口") {
        proxy.$http.post('addapiinterface', {
          title: formData.data.title,
          tag: formData.data.tag
        }, {
          headers: {
            'token': sessionStorage.getItem("token")
          }
        }).then(function (res) {
          if (res.data.status == 200) {
            addVisible.value = false;
            ElMessage({
              message: res.data.msg,
              type: 'success'
            });
            getTabledata();
          } else {
            ElMessage({
              message: res.data.msg,
              type: 'error'
            });
          }
        });
      } else if (cztitle.value == "编辑接口") {
        proxy.$http.post('updateapiinterface', {
          id: formData.data.id,
          title: formData.data.title,
          tag: formData.data.tag
        }, {
          headers: {
            'token': sessionStorage.getItem("token")
          }
        }).then(function (res) {
          if (res.data.status == 200) {
            addVisible.value = false;
            ElMessage({
              message: res.data.msg,
              type: 'success'
            });
            getTabledata();
          } else {
            ElMessage({
              message: res.data.msg,
              type: 'error'
            });
          }
        });
      }
    }; // 多选


    var multipleSelection = ref([]);

    var handleSelectionChange = function handleSelectionChange(val) {
      multipleSelection.value = val;

      if (multipleSelection.value.length > 0) {
        deldisabled.value = false;
      } else {
        deldisabled.value = true;
      }
    }; // 删除


    var deldisabled = ref(true);

    var delData = function delData(ids) {
      ElMessageBox.confirm('确认删除数据吗？', '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        proxy.$http.post('delapiinterface', {
          ids: ids
        }, {
          headers: {
            'token': sessionStorage.getItem("token")
          }
        }).then(function (res) {
          if (res.data.status == 200) {
            deldisabled.value = true;
            ElMessage({
              message: res.data.msg,
              type: 'success'
            });
            getTabledata();
          } else {
            ElMessage({
              message: res.data.msg,
              type: 'error'
            });
          }
        });
      }).catch(function () {});
    };

    var arr = [];

    var delMore = function delMore() {
      arr = [];
      var ids = "";

      if (multipleSelection.value.length == 1) {
        ids = multipleSelection.value[0].id;
      } else {
        for (var i in multipleSelection.value) {
          arr.push(multipleSelection.value[i].id);
        }

        ids = arr.join(",");
      }

      delData(ids);
    };

    var rolelist = reactive({
      arr: []
    }); // 角色列表

    var getRole = function getRole() {
      proxy.$http.post('rolelist', {
        pagesize: 100
      }, {
        headers: {
          'token': sessionStorage.getItem("token")
        }
      }).then(function (res) {
        if (res.data.status == 200) {
          rolelist.arr = res.data.data.data;
        } else {
          ElMessage({
            message: res.data.msg,
            type: 'error'
          });
        }
      });
    };

    getRole(); // 分页

    var handleSizeChange = function handleSizeChange(val) {
      pageinfo['pagesize'] = val;
      getTabledata();
    };

    var handleCurrentChange = function handleCurrentChange(val) {
      pageinfo['page'] = val;
      getTabledata();
    };

    return {
      changeFormdate: changeFormdate,
      rolelist: rolelist,
      getRole: getRole,
      getTabledata: getTabledata,
      tableData: tableData,
      multipleSelection: multipleSelection,
      handleSelectionChange: handleSelectionChange,
      manageRef: manageRef,
      searchData: searchData,
      toSearch: toSearch,
      defaultTime: defaultTime,
      cztitle: cztitle,
      formData: formData,
      pageinfo: pageinfo,
      addVisible: addVisible,
      openAdd: openAdd,
      openEdit: openEdit,
      toSumbit: toSumbit,
      deldisabled: deldisabled,
      delMore: delMore,
      delData: delData,
      handleSizeChange: handleSizeChange,
      handleCurrentChange: handleCurrentChange
    };
  }
};