import { reactive, ref } from "vue";
import vueImgVerify from "../components/verify.vue"; // 引入的子组件 

import { ElMessage } from 'element-plus';
import { getCurrentInstance } from 'vue';
import { useRouter } from "vue-router";
export default {
  components: {
    vueImgVerify: vueImgVerify
  },
  data: function data() {
    return {
      size: "large"
    };
  },
  setup: function setup() {
    var _getCurrentInstance = getCurrentInstance(),
        proxy = _getCurrentInstance.proxy;

    var verifyRef = ref(null);
    var router = useRouter(); // let code=ref()

    var state = reactive({
      verify: ""
    });
    var formData = reactive({
      username: "",
      password: ""
    });

    var toLogin = function toLogin() {
      console.log(verifyRef.value.imgCode);

      if (verifyRef.value.imgCode == state.verify.toUpperCase()) {
        proxy.$http.post('login', {
          username: formData.username,
          password: formData.password
        }, {}).then(function (res) {
          if (res.data.status == 200) {
            sessionStorage.setItem("token", res.data.data.token);
            sessionStorage.setItem("id", res.data.data.id);
            sessionStorage.setItem("username", res.data.data.username);
            sessionStorage.setItem("mch_id", res.data.data.mch_id);
            sessionStorage.setItem("admin_role_id", res.data.data.admin_role_id);
            router.push({
              path: "/"
            });
          } else {
            ElMessage({
              message: res.data.msg,
              type: 'warning'
            });
          }
        });
      } else {
        ElMessage({
          message: '验证码错误',
          type: 'warning'
        });
      }
    };

    return {
      toLogin: toLogin,
      state: state,
      formData: formData,
      verifyRef: verifyRef
    };
  }
};