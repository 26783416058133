import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.array.join.js";
import zhCn from 'element-plus/lib/locale/lang/zh-cn';
import { reactive, ref } from 'vue';
import { ElMessage, ElMessageBox } from 'element-plus';
import { getCurrentInstance } from 'vue'; // import { inject } from 'vue'

export default {
  data: function data() {
    return {
      locale: zhCn,
      value1: "",
      size: 'small'
    };
  },
  setup: function setup() {
    var activeName = ref('first');
    var cztitle = ref();
    var tctitle = ref();
    var manageRef = ref(null);
    var apioptions = reactive({
      data: {}
    }); //api列表

    var interfaceoptions = reactive({
      data: {}
    }); //接口列表

    var formData = reactive({
      data: {
        title: "",
        api_interface_id: ""
      }
    });
    var formtcData = reactive({
      api_id: "",
      title: "",
      unit_price: "",
      discount: "",
      total_no: "",
      total_price: "",
      term_validity: "",
      term_validity_unit: "m",
      describes: "",
      sort: "",
      unit: ""
    });
    var pageinfo = reactive({
      page: 1,
      pagesize: 10,
      total: ""
    });
    var tcpageinfo = reactive({
      page: 1,
      pagesize: 10,
      total: ""
    });
    var tableData = reactive({
      arr: []
    });
    var tableDatatc = reactive({
      arr: []
    });
    var searchTc = reactive({
      data: {
        api_id: ""
      }
    });

    var _getCurrentInstance = getCurrentInstance(),
        proxy = _getCurrentInstance.proxy;

    var getTabledata = function getTabledata() {
      proxy.$http.post('apilist', {
        page: pageinfo.page,
        pagesize: pageinfo.pagesize
      }, {
        headers: {
          'token': sessionStorage.getItem("token")
        }
      }).then(function (res) {
        if (res.data.status == 200) {
          tableData.arr = res.data.data.data;
          pageinfo['total'] = res.data.data.total;
        } else {
          ElMessage({
            message: res.data.msg,
            type: 'error'
          });
        }
      });
    };

    getTabledata(); // 套餐查询

    var toSearchTc = function toSearchTc() {
      getTabledatatc();
    }; // 套餐列表


    var getTabledatatc = function getTabledatatc() {
      proxy.$http.post('setmeallist', {
        page: tcpageinfo.page,
        pagesize: tcpageinfo.pagesize,
        api_id: searchTc.data.api_id
      }, {
        headers: {
          'token': sessionStorage.getItem("token")
        }
      }).then(function (res) {
        if (res.data.status == 200) {
          tableDatatc.arr = res.data.data.data;
          tcpageinfo['total'] = res.data.data.total;
        } else {
          ElMessage({
            message: res.data.msg,
            type: 'error'
          });
        }
      });
    };

    getTabledatatc(); // 添加套餐

    var tcVisible = ref(false);

    var openAddtc = function openAddtc() {
      formtcData.data = {
        api_id: "",
        title: "",
        unit_price: "",
        discount: "",
        total_no: "",
        total_price: "",
        term_validity: "",
        term_validity_unit: "m",
        describes: "",
        sort: ""
      };
      tcVisible.value = true;
      tctitle.value = "添加套餐";
    }; // 编辑套餐


    var openEdittc = function openEdittc(id) {
      proxy.$http.post('setmealpage', {
        id: id
      }, {
        headers: {
          'token': sessionStorage.getItem("token")
        }
      }).then(function (res) {
        if (res.data.status == 200) {
          tcVisible.value = true;
          tctitle.value = "编辑套餐";
          formtcData.data = res.data.data.data;
        } else {
          ElMessage({
            message: res.data.msg,
            type: 'error'
          });
        }
      });
    }; // 提交套餐


    var toSumbittc = function toSumbittc() {
      if (tctitle.value == "添加套餐") {
        proxy.$http.post('addsetmeal', {
          api_id: formtcData.data.api_id,
          title: formtcData.data.title,
          unit_price: formtcData.data.unit_price,
          discount: formtcData.data.discount,
          total_no: formtcData.data.total_no,
          total_price: formtcData.data.total_price,
          term_validity: formtcData.data.term_validity,
          term_validity_unit: formtcData.data.term_validity_unit,
          describes: formtcData.data.describes,
          sort: formtcData.data.sort,
          unit: formtcData.data.unit
        }, {
          headers: {
            'token': sessionStorage.getItem("token")
          }
        }).then(function (res) {
          if (res.data.status == 200) {
            tcVisible.value = false;
            ElMessage({
              message: res.data.msg,
              type: 'success'
            });
            getTabledatatc();
          } else {
            ElMessage({
              message: res.data.msg,
              type: 'error'
            });
          }
        });
      } else if (tctitle.value == "编辑套餐") {
        proxy.$http.post('updatesetmeal', {
          id: formtcData.data.id,
          api_id: formtcData.data.api_id,
          title: formtcData.data.title,
          unit_price: formtcData.data.unit_price,
          discount: formtcData.data.discount,
          total_no: formtcData.data.total_no,
          total_price: formtcData.data.total_price,
          term_validity: formtcData.data.term_validity,
          term_validity_unit: formtcData.data.term_validity_unit,
          describes: formtcData.data.describes,
          sort: formtcData.data.sort,
          unit: formtcData.data.unit
        }, {
          headers: {
            'token': sessionStorage.getItem("token")
          }
        }).then(function (res) {
          if (res.data.status == 200) {
            tcVisible.value = false;
            ElMessage({
              message: res.data.msg,
              type: 'success'
            });
            getTabledatatc();
          } else {
            ElMessage({
              message: res.data.msg,
              type: 'error'
            });
          }
        });
      }
    }; // 删除套餐


    var multipleSelectiontc = ref([]);
    var deltcdisabled = ref(true);

    var handleSelectionChangetc = function handleSelectionChangetc(val) {
      multipleSelectiontc.value = val;

      if (multipleSelectiontc.value.length > 0) {
        deltcdisabled.value = false;
      } else {
        deltcdisabled.value = true;
      }
    };

    var delDatatc = function delDatatc(ids) {
      ElMessageBox.confirm('确认删除数据吗？', '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        proxy.$http.post('delsetmeal', {
          ids: ids
        }, {
          headers: {
            'token': sessionStorage.getItem("token")
          }
        }).then(function (res) {
          if (res.data.status == 200) {
            deltcdisabled.value = true;
            ElMessage({
              message: res.data.msg,
              type: 'success'
            });
            getTabledatatc();
          } else {
            ElMessage({
              message: res.data.msg,
              type: 'error'
            });
          }
        });
      }).catch(function () {});
    };

    var delMoretc = function delMoretc() {
      arr = [];
      var ids = "";

      if (multipleSelectiontc.value.length == 1) {
        ids = multipleSelectiontc.value[0].id;
      } else {
        for (var i in multipleSelectiontc.value) {
          arr.push(multipleSelectiontc.value[i].id);
        }

        ids = arr.join(",");
      }

      delDatatc(ids);
    }; // 添加API


    var addVisible = ref(false);

    var openAdd = function openAdd() {
      formData.data = {
        title: "",
        api_interface_id: ""
      };
      cztitle.value = "添加API";
      addVisible.value = true;
    }; // 编辑API
    // API详情


    var openEdit = function openEdit(id) {
      proxy.$http.post('apipage', {
        id: id
      }, {
        headers: {
          'token': sessionStorage.getItem("token")
        }
      }).then(function (res) {
        if (res.data.status == 200) {
          cztitle.value = "编辑API";
          addVisible.value = true;
          formData.data = res.data.data.data;
        } else {
          ElMessage({
            message: res.data.msg,
            type: 'error'
          });
        }
      });
    }; // 提交


    var toSumbit = function toSumbit() {
      if (cztitle.value == "添加API") {
        proxy.$http.post('addapi', {
          title: formData.data.title,
          api_interface_id: formData.data.api_interface_id
        }, {
          headers: {
            'token': sessionStorage.getItem("token")
          }
        }).then(function (res) {
          if (res.data.status == 200) {
            addVisible.value = false;
            ElMessage({
              message: res.data.msg,
              type: 'success'
            });
            getTabledata();
          } else {
            ElMessage({
              message: res.data.msg,
              type: 'error'
            });
          }
        });
      } else if (cztitle.value == "编辑API") {
        proxy.$http.post('updateapi', {
          id: formData.data.id,
          title: formData.data.title,
          api_interface_id: formData.data.api_interface_id
        }, {
          headers: {
            'token': sessionStorage.getItem("token")
          }
        }).then(function (res) {
          if (res.data.status == 200) {
            addVisible.value = false;
            ElMessage({
              message: res.data.msg,
              type: 'success'
            });
            getTabledata();
          } else {
            ElMessage({
              message: res.data.msg,
              type: 'error'
            });
          }
        });
      }
    }; // 多选


    var multipleSelection = ref([]);

    var handleSelectionChange = function handleSelectionChange(val) {
      multipleSelection.value = val;

      if (multipleSelection.value.length > 0) {
        deldisabled.value = false;
      } else {
        deldisabled.value = true;
      }
    }; // 删除


    var deldisabled = ref(true);

    var delData = function delData(ids) {
      ElMessageBox.confirm('确认删除数据吗？', '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        proxy.$http.post('delapi', {
          ids: ids
        }, {
          headers: {
            'token': sessionStorage.getItem("token")
          }
        }).then(function (res) {
          if (res.data.status == 200) {
            deldisabled.value = true;
            ElMessage({
              message: res.data.msg,
              type: 'success'
            });
            getTabledata();
          } else {
            ElMessage({
              message: res.data.msg,
              type: 'error'
            });
          }
        });
      }).catch(function () {});
    };

    var arr = [];

    var delMore = function delMore() {
      arr = [];
      var ids = "";

      if (multipleSelection.value.length == 1) {
        ids = multipleSelection.value[0].id;
      } else {
        for (var i in multipleSelection.value) {
          arr.push(multipleSelection.value[i].id);
        }

        ids = arr.join(",");
      }

      delData(ids);
    }; // 接口列表


    var getinterface = function getinterface() {
      proxy.$http.post('apiinterfacelist', {
        pagesize: 200
      }, {
        headers: {
          'token': sessionStorage.getItem("token")
        }
      }).then(function (res) {
        if (res.data.status == 200) {
          interfaceoptions.data = res.data.data.data;
        } else {
          ElMessage({
            message: res.data.msg,
            type: 'error'
          });
        }
      });
    };

    getinterface(); // api列表

    var getapi = function getapi() {
      proxy.$http.post('apilist', {
        pagesize: 200
      }, {
        headers: {
          'token': sessionStorage.getItem("token")
        }
      }).then(function (res) {
        if (res.data.status == 200) {
          apioptions.data = res.data.data.data;
        } else {
          ElMessage({
            message: res.data.msg,
            type: 'error'
          });
        }
      });
    };

    getapi(); // 分页

    var handleSizeChange = function handleSizeChange(val) {
      pageinfo['pagesize'] = val;
      getTabledata();
    };

    var handleCurrentChange = function handleCurrentChange(val) {
      pageinfo['page'] = val;
      getTabledata();
    };

    var handleSizeChangetc = function handleSizeChangetc(val) {
      tcpageinfo['pagesize'] = val;
      getTabledatatc();
    };

    var handleCurrentChangetc = function handleCurrentChangetc(val) {
      tcpageinfo['page'] = val;
      getTabledatatc();
    };

    return {
      activeName: activeName,
      getapi: getapi,
      apioptions: apioptions,
      getTabledata: getTabledata,
      tableData: tableData,
      multipleSelection: multipleSelection,
      handleSelectionChange: handleSelectionChange,
      manageRef: manageRef,
      cztitle: cztitle,
      formData: formData,
      pageinfo: pageinfo,
      addVisible: addVisible,
      openAdd: openAdd,
      openEdit: openEdit,
      toSumbit: toSumbit,
      deldisabled: deldisabled,
      delMore: delMore,
      delData: delData,
      handleSizeChange: handleSizeChange,
      handleCurrentChange: handleCurrentChange,
      getTabledatatc: getTabledatatc,
      tableDatatc: tableDatatc,
      deltcdisabled: deltcdisabled,
      delMoretc: delMoretc,
      delDatatc: delDatatc,
      multipleSelectiontc: multipleSelectiontc,
      handleSelectionChangetc: handleSelectionChangetc,
      tctitle: tctitle,
      formtcData: formtcData,
      tcpageinfo: tcpageinfo,
      tcVisible: tcVisible,
      openAddtc: openAddtc,
      openEdittc: openEdittc,
      toSumbittc: toSumbittc,
      handleSizeChangetc: handleSizeChangetc,
      handleCurrentChangetc: handleCurrentChangetc,
      toSearchTc: toSearchTc,
      searchTc: searchTc,
      interfaceoptions: interfaceoptions,
      getinterface: getinterface
    };
  }
};