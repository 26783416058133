import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
import "core-js/modules/es.array.join.js";
import zhCn from 'element-plus/lib/locale/lang/zh-cn';
import { ElMessage, ElMessageBox } from 'element-plus';
import { reactive, ref } from 'vue';
import { getCurrentInstance } from 'vue';
export default {
  data: function data() {
    return {
      locale: zhCn
    };
  },
  setup: function setup() {
    var tableData = reactive({
      arr: []
    });
    var deldisabled = ref(false);
    var addVisible = ref(false);
    var ipTitle = ref();
    var editid = ref();
    var formData = reactive({
      data: {}
    });
    var line = ref(10);

    var _getCurrentInstance = getCurrentInstance(),
        proxy = _getCurrentInstance.proxy;

    var pageinfo = reactive({
      page: 1,
      pagesize: 10,
      total: ""
    });

    var getTabledata = function getTabledata() {
      proxy.$http.post('blackiplist', {
        page: pageinfo.page,
        pagesize: pageinfo.pagesize,
        search: formData.data.search,
        admin_user_id: sessionStorage.getItem("id"),
        admin_user_api_id: sessionStorage.getItem("apiid")
      }, {
        headers: {
          'token': sessionStorage.getItem("token")
        }
      }).then(function (res) {
        if (res.data.status == 200) {
          tableData.arr = res.data.data.data;
          pageinfo['total'] = res.data.data.total;
        }
      });
    };

    getTabledata(); // 添加、编辑

    var openAdd = function openAdd() {
      formData.data.ip = "";
      ipTitle.value = "添加IP白名单";
      addVisible.value = true;
      line.value = 10;
    };

    var openEdit = function openEdit(id) {
      ipTitle.value = "编辑IP白名单";
      line.value = 1;
      editid.value = id;
      proxy.$http.post('blackippage', {
        id: id
      }, {
        headers: {
          'token': sessionStorage.getItem("token")
        }
      }).then(function (res) {
        if (res.data.status == 200) {
          formData.data = res.data.data.data;
          addVisible.value = true;
        } else {
          ElMessage({
            message: res.data.msg,
            type: 'error'
          });
        }
      });
    };

    var toSumbit = function toSumbit() {
      if (ipTitle.value == "添加IP白名单") {
        proxy.$http.post('addblackip', {
          ips: formData.data.ip,
          admin_user_id: sessionStorage.getItem("id"),
          admin_user_api_id: sessionStorage.getItem("apiid")
        }, {
          headers: {
            'token': sessionStorage.getItem("token")
          }
        }).then(function (res) {
          if (res.data.status == 200) {
            addVisible.value = false;
            ElMessage({
              message: res.data.msg,
              type: 'success'
            });
            getTabledata();
          } else {
            ElMessage({
              message: res.data.msg,
              type: 'error'
            });
          }
        });
      } else if (ipTitle.value == "编辑IP白名单") {
        proxy.$http.post('updateblackip', {
          id: editid.value,
          ip: formData.data.ip,
          admin_user_id: sessionStorage.getItem("id"),
          admin_user_api_id: sessionStorage.getItem("apiid")
        }, {
          headers: {
            'token': sessionStorage.getItem("token")
          }
        }).then(function (res) {
          if (res.data.status == 200) {
            addVisible.value = false;
            ElMessage({
              message: res.data.msg,
              type: 'success'
            });
            getTabledata();
          } else {
            ElMessage({
              message: res.data.msg,
              type: 'error'
            });
          }
        });
      }
    }; // 删除


    var arr = [];

    var delDataAll = function delDataAll() {
      arr = [];
      var ids = "";

      if (multipleSelection.value.length == 1) {
        ids = multipleSelection.value[0].id;
      } else {
        for (var i in multipleSelection.value) {
          arr.push(multipleSelection.value[i].id);
        }

        ids = arr.join(",");
      }

      delData(ids);
    };

    var delData = function delData(ids) {
      ElMessageBox.confirm('确认删除数据吗？', '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        proxy.$http.post('delblackip', {
          ids: ids
        }, {
          headers: {
            'token': sessionStorage.getItem("token")
          }
        }).then(function (res) {
          if (res.data.status == 200) {
            deldisabled.value = true;
            ElMessage({
              message: res.data.msg,
              type: 'success'
            });
            getTabledata();
          } else {
            ElMessage({
              message: res.data.msg,
              type: 'error'
            });
          }
        });
      }).catch(function () {});
    }; // 多选


    var multipleSelection = ref([]);

    var handleSelectionChange = function handleSelectionChange(val) {
      multipleSelection.value = val;

      if (multipleSelection.value.length > 0) {
        deldisabled.value = false;
      } else {
        deldisabled.value = true;
      }
    }; // 分页


    var handleSizeChange = function handleSizeChange(val) {
      pageinfo['pagesize'] = val;
      getTabledata();
    };

    var handleCurrentChange = function handleCurrentChange(val) {
      pageinfo['page'] = val;
      getTabledata();
    };

    return {
      line: line,
      toSumbit: toSumbit,
      addVisible: addVisible,
      formData: formData,
      openAdd: openAdd,
      multipleSelection: multipleSelection,
      handleSelectionChange: handleSelectionChange,
      deldisabled: deldisabled,
      delData: delData,
      ipTitle: ipTitle,
      openEdit: openEdit,
      delDataAll: delDataAll,
      getTabledata: getTabledata,
      tableData: tableData,
      pageinfo: pageinfo,
      handleSizeChange: handleSizeChange,
      handleCurrentChange: handleCurrentChange
    };
  }
};