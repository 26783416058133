import "core-js/modules/es.array.splice.js";
import { ref } from 'vue';
import { getCurrentInstance } from 'vue';
import { ElMessage } from 'element-plus';
export default {
  data: function data() {
    return {
      noClick: true
    };
  },
  setup: function setup() {
    var dowloadUrl = ref(getCurrentInstance().appContext.config.globalProperties.$dowloadUrl);
    dowloadUrl.value = dowloadUrl.value + "/storage/template/%E5%9C%A8%E7%BA%BF%E6%A3%80%E6%B5%8B%E6%A8%A1%E6%9D%BF.xlsx";

    var _getCurrentInstance = getCurrentInstance(),
        proxy = _getCurrentInstance.proxy; // 获取appid


    var apiid = ref();

    var getvosurl = function getvosurl() {
      proxy.$http.post('blackvosrewritelist', {
        apikey: sessionStorage.getItem("apikey")
      }, {
        headers: {
          'token': sessionStorage.getItem("token")
        }
      }).then(function (res) {
        if (res.data.status == 200) {
          apiid.value = res.data.data.apiid;
          console.log(res);
        }
      });
    };

    getvosurl(); // 上传表格

    var fullscreenLoading = ref(false);
    var tableFile = ref();

    var beforeTableUpload = function beforeTableUpload(file, fileList) {
      console.log(file);
      tableFile.value = fileList[0].raw;

      if (fileList.length > 1) {
        fileList.splice(0, 1);
      }
    };

    var uploadtable = function uploadtable() {
      fullscreenLoading.value = true;
      var formData = new FormData();
      formData.append("mch_id", sessionStorage.getItem("mch_id"));
      formData.append("file", tableFile.value);
      proxy.$http.post('blackonlinedetectexcel?apiid=' + apiid.value, {
        "mch_id": sessionStorage.getItem("mch_id"),
        admin_user_api_id: sessionStorage.getItem("apiid"),
        "file": tableFile.value
      }, {
        headers: {
          "Content-Type": "multipart/form-data",
          'token': sessionStorage.getItem("token")
        }
      }).then(function (res) {
        if (res.data.status == 200) {
          fullscreenLoading.value = false;
          ElMessage({
            message: res.data.msg,
            type: 'success'
          });
        } else {
          fullscreenLoading.value = false;
          ElMessage({
            message: res.data.msg,
            type: 'error'
          });
        }
      });
    };

    return {
      beforeTableUpload: beforeTableUpload,
      fullscreenLoading: fullscreenLoading,
      uploadtable: uploadtable,
      dowloadUrl: dowloadUrl
    };
  }
};