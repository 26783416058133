import { reactive, ref } from 'vue';
import zhCn from 'element-plus/lib/locale/lang/zh-cn';
import { getCurrentInstance } from 'vue';
import { ElMessage } from 'element-plus';
import * as echarts from 'echarts'; // import moment from "moment";

export default {
  data: function data() {
    return {
      locale: zhCn,
      size: "small",
      closable: false
    };
  },
  setup: function setup() {
    var pageinfo = reactive({
      page: 1,
      pagesize: 10,
      total: ""
    });

    var _getCurrentInstance = getCurrentInstance(),
        proxy = _getCurrentInstance.proxy; // 查询


    var called_number = ref();

    var toSearch = function toSearch() {
      getTableData();
    }; // 统计表格


    var tableData = reactive({
      arr: []
    }); // 列表

    var getTableData = function getTableData() {
      // var date=""
      // if(searchData.create_time&&searchData.create_time[0]){
      //     date=moment(searchData.create_time[0]+" 00:00:00").unix()+"-"+moment(searchData.create_time[1]+" 23:59:59").unix()
      // }
      proxy.$http.post('ttsaivoicelist', {
        page: pageinfo.page,
        pagesize: pageinfo.pagesize,
        admin_user_id: sessionStorage.getItem("id"),
        admin_user_api_id: sessionStorage.getItem("apiid")
      }, {
        headers: {
          'token': sessionStorage.getItem("token")
        }
      }).then(function (res) {
        if (res.data.status == 200) {
          tableData.arr = res.data.data.data;
          pageinfo['total'] = res.data.data.total;
        } else {
          ElMessage({
            message: res.data.msg,
            type: 'error'
          });
        }
      });
    };

    getTableData(); // 图表

    var getCharts = function getCharts() {
      // var date=""
      // if(searchData.create_time&&searchData.create_time[0]){
      //     date=moment(searchData.create_time[0]+" 00:00:00").unix()+"-"+moment(searchData.create_time[1]+" 23:59:59").unix()
      // }
      proxy.$http.post('ttsaivoicetimestatistics', {
        admin_user_id: sessionStorage.getItem("id"),
        admin_user_api_id: sessionStorage.getItem("apiid")
      }, {
        headers: {
          'token': sessionStorage.getItem("token")
        }
      }).then(function (res) {
        if (res.data.status == 200) {
          var myChart = echarts.init(document.getElementById('mycharts'));
          myChart.setOption({
            title: {
              left: 'center',
              text: '近15天TTS使用情况'
            },
            xAxis: {
              data: res.data.data.date_minute
            },
            yAxis: {},
            // dataZoom:[{
            //     type:"inside",
            //     start:70, 
            // }],
            grid: {
              top: '17%',
              left: "3%",
              right: "3%",
              bottom: "8%"
            },
            series: [{
              name: "用户量",
              type: "line",
              itemStyle: {
                normal: {
                  lineStyle: {
                    width: 3,
                    color: "#409EFC"
                  }
                }
              },
              data: res.data.data.user_count
            }]
          });
        } else {
          ElMessage({
            message: res.data.msg,
            type: 'error'
          });
        }
      });
    };

    getCharts(); // 分页

    var handleSizeChange = function handleSizeChange(val) {
      pageinfo['pagesize'] = val;
      getTableData();
    };

    var handleCurrentChange = function handleCurrentChange(val) {
      pageinfo['page'] = val;
      getTableData();
    };

    return {
      handleSizeChange: handleSizeChange,
      handleCurrentChange: handleCurrentChange,
      pageinfo: pageinfo,
      tableData: tableData,
      toSearch: toSearch,
      called_number: called_number
    };
  }
};