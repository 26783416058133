import zhCn from 'element-plus/lib/locale/lang/zh-cn';
import { reactive } from 'vue-demi';
import { ElMessage } from 'element-plus';
import { getCurrentInstance } from 'vue';
export default {
  data: function data() {
    return {
      locale: zhCn
    };
  },
  setup: function setup() {
    var _getCurrentInstance = getCurrentInstance(),
        proxy = _getCurrentInstance.proxy;

    var formData = reactive({
      data: {}
    });

    var getDetail = function getDetail() {
      proxy.$http.post('systemsetpage', {
        id: 1
      }, {
        headers: {
          'token': sessionStorage.getItem("token")
        }
      }).then(function (res) {
        if (res.data.status == 200) {
          formData.data = res.data.data.data;
        } else {
          ElMessage({
            message: res.data.msg,
            type: 'error'
          });
        }
      });
    };

    getDetail();

    var toSumbit = function toSumbit() {
      proxy.$http.post('updatesystemset', {
        id: 1,
        hasee: formData.data.hasee
      }, {
        headers: {
          'token': sessionStorage.getItem("token")
        }
      }).then(function (res) {
        if (res.data.status == 200) {
          ElMessage({
            message: res.data.msg,
            type: 'success'
          });
          getDetail();
        } else {
          ElMessage({
            message: res.data.msg,
            type: 'error'
          });
        }
      });
    }; // const { proxy } = getCurrentInstance();


    return {
      toSumbit: toSumbit,
      formData: formData
    };
  }
};