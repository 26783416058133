// import {DataAnalysis,User,Setting,Wallet,ArrowDown,UserFilled} from '@element-plus/icons-vue'
import { useRouter } from "vue-router";
import { reactive, ref } from 'vue';
import { ElMessage } from 'element-plus';
import { getCurrentInstance } from 'vue';
export default {
  data: function data() {
    return {
      direction: "ttb"
    };
  },
  // components:{
  //   DataAnalysis,User,Setting,Wallet,ArrowDown,UserFilled
  // },
  setup: function setup() {
    var router = useRouter();
    var username = ref();
    var menulist = reactive({
      arr: []
    });
    username.value = sessionStorage.getItem("username");

    var _getCurrentInstance = getCurrentInstance(),
        proxy = _getCurrentInstance.proxy; // 返回主页面


    var toIndex = function toIndex() {
      router.push({
        path: "/all/apilist"
      });
    };

    var toFirst = function toFirst() {
      router.push({
        path: "/cloud/telexport"
      });
    }; // 其他接口菜单


    var tableData = reactive({
      data: []
    });

    var getTabledata = function getTabledata() {
      proxy.$http.post('apilist', {
        page: 1,
        pagesize: 100,
        admin_user_id: sessionStorage.getItem("id")
      }, {
        headers: {
          'token': sessionStorage.getItem("token")
        }
      }).then(function (res) {
        if (res.data.status == 200) {
          tableData.arr = res.data.data.data;
        } else {
          ElMessage({
            message: res.data.msg,
            type: 'error'
          });
        }
      });
    };

    getTabledata();

    var toStatistics = function toStatistics(id, czid, url, title) {
      var href = "/" + url;
      sessionStorage.setItem("apiid", id);
      sessionStorage.setItem("czid", czid);
      router.push({
        path: href,
        query: {
          apiname: title
        }
      });
    }; // 标题


    var apititle = ref();

    var getapiData = function getapiData() {
      proxy.$http.post('adminuserapipage', {
        id: sessionStorage.getItem("apiid")
      }, {
        headers: {
          'token': sessionStorage.getItem("token")
        }
      }).then(function (res) {
        if (res.data.status == 200) {
          if (res.data.data.data) {
            apititle.value = res.data.data.data.title_api;
          }
        } else {
          ElMessage({
            message: res.data.msg,
            type: 'error'
          });
        }
      });
    };

    getapiData(); // 菜单

    var getMenu = function getMenu() {
      proxy.$http.post('nodelist', {
        menu_status: 1
      }, {
        headers: {
          'token': sessionStorage.getItem("token")
        }
      }).then(function (res) {
        if (res.data.status == 200) {
          menulist.arr = res.data.data;
        } else {
          ElMessage({
            message: res.data.msg,
            type: 'error'
          });
        }
      });
    };

    getMenu();

    var toPage = function toPage(url) {
      if (url == "/cloud/cloudData") {
        router.push({
          path: url,
          query: {
            apiname: 'tts语音播报'
          }
        });
      } else {
        router.push({
          path: url
        });
      }
    };

    var toLoginout = function toLoginout() {
      sessionStorage.setItem("token", "");
      router.push({
        path: "/login"
      });
    };

    var topDrawer = ref(false);
    return {
      tableData: tableData,
      toStatistics: toStatistics,
      toIndex: toIndex,
      apititle: apititle,
      toFirst: toFirst,
      getMenu: getMenu,
      menulist: menulist,
      toPage: toPage,
      username: username,
      toLoginout: toLoginout,
      topDrawer: topDrawer
    };
  }
};