import zhCn from 'element-plus/lib/locale/lang/zh-cn'; // import { ElMessage ,ElMessageBox} from 'element-plus'

import { reactive } from 'vue';
import { getCurrentInstance } from 'vue';
export default {
  data: function data() {
    return {
      locale: zhCn
    };
  },
  setup: function setup() {
    var _getCurrentInstance = getCurrentInstance(),
        proxy = _getCurrentInstance.proxy;

    var vosurl = reactive({
      arr: []
    });

    var getvosurl = function getvosurl() {
      proxy.$http.post('blackvosrewritelist', {
        apikey: sessionStorage.getItem("apikey")
      }, {
        headers: {
          'token': sessionStorage.getItem("token")
        }
      }).then(function (res) {
        if (res.data.status == 200) {
          vosurl.arr = res.data.data.data;
          console.log(res);
        }
      });
    };

    getvosurl();
    return {
      getvosurl: getvosurl,
      vosurl: vosurl
    };
  }
};