import "core-js/modules/es.array.join.js";
import { reactive, ref } from 'vue';
import zhCn from 'element-plus/lib/locale/lang/zh-cn';
import { getCurrentInstance } from 'vue';
import { ElMessage, ElMessageBox } from 'element-plus'; // import moment from "moment";

export default {
  data: function data() {
    return {
      locale: zhCn,
      size: "small"
    };
  },
  setup: function setup() {
    var pageinfo = reactive({
      page: 1,
      pagesize: 10,
      total: ""
    });

    var _getCurrentInstance = getCurrentInstance(),
        proxy = _getCurrentInstance.proxy;

    var searchData = reactive({
      batch: "",
      play_state: "0"
    }); // 查询

    var toSearch = function toSearch() {
      getTableData();
    }; // 统计表格


    var tableData = reactive({
      arr: []
    });

    var getTableData = function getTableData() {
      proxy.$http.post('ttsphoneslist', {
        page: pageinfo.page,
        pagesize: pageinfo.pagesize,
        play_state: searchData.play_state,
        batch: searchData.batch
      }, {
        headers: {
          'token': sessionStorage.getItem("token")
        }
      }).then(function (res) {
        if (res.data.status == 200) {
          tableData.arr = res.data.data.data;
          pageinfo['total'] = res.data.data.total;
        } else {
          ElMessage({
            message: res.data.msg,
            type: 'error'
          });
        }
      });
    };

    getTableData(); // 呼叫、暂停

    var isplay = ref(0);

    var toPlay = function toPlay() {
      proxy.$http.post('batchcallphonetxt', {
        admin_user_id: sessionStorage.getItem("id"),
        admin_user_api_id: sessionStorage.getItem("apiid")
      }, {
        headers: {
          'token': sessionStorage.getItem("token")
        }
      }).then(function (res) {
        if (res.data.status == 200) {
          getTableData();
          isplay.value = 1;
          ElMessage({
            message: "拨打成功",
            type: 'success'
          });
        } else {
          ElMessage({
            message: res.data.msg,
            type: 'error'
          });
        }
      });
    };

    var toPause = function toPause() {
      proxy.$http.post('delbatchcallphonetxt', {
        admin_user_id: sessionStorage.getItem("id"),
        admin_user_api_id: sessionStorage.getItem("apiid")
      }, {
        headers: {
          'token': sessionStorage.getItem("token")
        }
      }).then(function (res) {
        if (res.data.status == 200) {
          isplay.value = 0;
          ElMessage({
            message: "暂停成功",
            type: 'success'
          });
        } else {
          ElMessage({
            message: res.data.msg,
            type: 'error'
          });
        }
      });
    }; // 多选


    var deldisabled = ref(true);
    var multipleSelection = ref([]);

    var handleSelectionChange = function handleSelectionChange(val) {
      console.log(multipleSelection.value);
      multipleSelection.value = val;

      if (multipleSelection.value.length > 0) {
        deldisabled.value = false;
      } else {
        deldisabled.value = true;
      }
    }; // 删除
    // 删除


    var delData = function delData(ids) {
      ElMessageBox.confirm('确认删除数据吗？', '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        proxy.$http.post('delttsphones', {
          ids: ids
        }, {
          headers: {
            'token': sessionStorage.getItem("token")
          }
        }).then(function (res) {
          if (res.data.status == 200) {
            deldisabled.value = true;
            ElMessage({
              message: res.data.msg,
              type: 'success'
            });
            getTableData();
          } else {
            ElMessage({
              message: res.data.msg,
              type: 'error'
            });
          }
        });
      }).catch(function () {});
    };

    var arr = [];

    var delMore = function delMore() {
      arr = [];
      var ids = "";

      if (multipleSelection.value.length == 1) {
        ids = multipleSelection.value[0].id;
      } else {
        for (var i in multipleSelection.value) {
          arr.push(multipleSelection.value[i].id);
        }

        ids = arr.join(",");
      }

      delData(ids);
    }; // 分页


    var handleSizeChange = function handleSizeChange(val) {
      pageinfo['pagesize'] = val;
      getTableData();
    };

    var handleCurrentChange = function handleCurrentChange(val) {
      pageinfo['page'] = val;
      getTableData();
    };

    return {
      handleSelectionChange: handleSelectionChange,
      delMore: delMore,
      deldisabled: deldisabled,
      isplay: isplay,
      toPlay: toPlay,
      toPause: toPause,
      tableData: tableData,
      toSearch: toSearch,
      searchData: searchData,
      handleSizeChange: handleSizeChange,
      handleCurrentChange: handleCurrentChange,
      pageinfo: pageinfo
    };
  }
};