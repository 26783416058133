import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.array.join.js";
import zhCn from 'element-plus/lib/locale/lang/zh-cn';
import { ElMessage, ElMessageBox } from 'element-plus';
import { reactive, ref } from 'vue';
import { getCurrentInstance } from 'vue';
export default {
  data: function data() {
    return {
      locale: zhCn
    };
  },
  setup: function setup() {
    var cztitle = ref();
    var tableData = reactive({
      arr: []
    });
    var deldisabled = ref(false);
    var addVisible = ref(false);
    var formData = reactive({
      data: {
        pid: 1,
        title: "",
        sort: ""
      }
    });

    var _getCurrentInstance = getCurrentInstance(),
        proxy = _getCurrentInstance.proxy;

    var pageinfo = reactive({
      page: 1,
      pagesize: 10,
      total: ""
    });

    var getTabledata = function getTabledata() {
      proxy.$http.post('industrylist', {
        page: pageinfo.page,
        pagesize: pageinfo.pagesize
      }, {
        headers: {
          'token': sessionStorage.getItem("token")
        }
      }).then(function (res) {
        if (res.data.status == 200) {
          tableData.arr = res.data.data.data;
          pageinfo['total'] = res.data.data.total;
        } else {
          ElMessage({
            message: res.data.msg,
            type: 'error'
          });
        }
      });
    };

    getTabledata(); // 添加、编辑

    var addOpen = function addOpen() {
      formData.data = {
        pid: 0,
        title: "",
        sort: ""
      };
      addVisible.value = true;
      cztitle.value = "添加行业";
    };

    var toSumbit = function toSumbit() {
      if (cztitle.value == "添加行业") {
        proxy.$http.post('addindustry', {
          pid: formData.data.pid,
          title: formData.data.title,
          sort: formData.data.sort
        }, {
          headers: {
            'token': sessionStorage.getItem("token")
          }
        }).then(function (res) {
          if (res.data.status == 200) {
            addVisible.value = false;
            ElMessage({
              message: res.data.msg,
              type: 'success'
            });
            getTabledata();
          } else {
            ElMessage({
              message: res.data.msg,
              type: 'error'
            });
          }
        });
      } else if (cztitle.value == "编辑行业") {
        proxy.$http.post('updateindustry', {
          id: formData.data.id,
          pid: formData.data.pid,
          title: formData.data.title,
          sort: formData.data.sort
        }, {
          headers: {
            'token': sessionStorage.getItem("token")
          }
        }).then(function (res) {
          if (res.data.status == 200) {
            addVisible.value = false;
            ElMessage({
              message: res.data.msg,
              type: 'success'
            });
            getTabledata();
          } else {
            ElMessage({
              message: res.data.msg,
              type: 'error'
            });
          }
        });
      }
    }; // 编辑用户


    var openEdit = function openEdit(id) {
      proxy.$http.post('industrypage', {
        id: id
      }, {
        headers: {
          'token': sessionStorage.getItem("token")
        }
      }).then(function (res) {
        if (res.data.status == 200) {
          cztitle.value = "编辑行业";
          addVisible.value = true;
          formData.data = res.data.data.data;
        } else {
          ElMessage({
            message: res.data.msg,
            type: 'error'
          });
        }
      });
    }; // 删除
    // 删除


    var arr = [];

    var delDataAll = function delDataAll() {
      arr = [];
      var ids = "";

      if (multipleSelection.value.length == 1) {
        ids = multipleSelection.value[0].id;
      } else {
        for (var i in multipleSelection.value) {
          arr.push(multipleSelection.value[i].id);
        }

        ids = arr.join(",");
      }

      delData(ids);
    };

    var delData = function delData(ids) {
      ElMessageBox.confirm('确认删除数据吗？', '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        proxy.$http.post('delindustry', {
          ids: ids
        }, {
          headers: {
            'token': sessionStorage.getItem("token")
          }
        }).then(function (res) {
          if (res.data.status == 200) {
            deldisabled.value = true;
            ElMessage({
              message: res.data.msg,
              type: 'success'
            });
            getTabledata();
          } else {
            ElMessage({
              message: res.data.msg,
              type: 'error'
            });
          }
        });
      }).catch(function () {});
    }; // 多选


    var multipleSelection = ref([]);

    var handleSelectionChange = function handleSelectionChange(val) {
      multipleSelection.value = val;

      if (multipleSelection.value.length > 0) {
        deldisabled.value = false;
      } else {
        deldisabled.value = true;
      }
    }; // 分页


    var handleSizeChange = function handleSizeChange(val) {
      pageinfo['pagesize'] = val;
      getTabledata();
    };

    var handleCurrentChange = function handleCurrentChange(val) {
      pageinfo['page'] = val;
      getTabledata();
    };

    return {
      handleCurrentChange: handleCurrentChange,
      handleSizeChange: handleSizeChange,
      pageinfo: pageinfo,
      toSumbit: toSumbit,
      addVisible: addVisible,
      formData: formData,
      addOpen: addOpen,
      openEdit: openEdit,
      delDataAll: delDataAll,
      multipleSelection: multipleSelection,
      handleSelectionChange: handleSelectionChange,
      deldisabled: deldisabled,
      delData: delData,
      getTabledata: getTabledata,
      tableData: tableData
    };
  }
};