import "core-js/modules/es.array.join.js";
import { useRouter } from "vue-router";
import zhCn from 'element-plus/lib/locale/lang/zh-cn';
import { ElMessage, ElMessageBox } from 'element-plus';
import { reactive, ref } from 'vue';
import { getCurrentInstance } from 'vue';
export default {
  data: function data() {
    return {
      locale: zhCn
    };
  },
  setup: function setup() {
    var router = useRouter();
    var tableData = reactive({
      arr: []
    });
    var deldisabled = ref(false);
    var pageinfo = reactive({
      page: 1,
      pagesize: 10,
      total: ""
    });

    var _getCurrentInstance = getCurrentInstance(),
        proxy = _getCurrentInstance.proxy;

    var getTabledata = function getTabledata() {
      proxy.$http.post('blackfilterrulelist', {
        page: pageinfo.page,
        pagesize: pageinfo.pagesize,
        admin_user_id: sessionStorage.getItem("id"),
        admin_user_api_id: sessionStorage.getItem("apiid")
      }, {
        headers: {
          'token': sessionStorage.getItem("token")
        }
      }).then(function (res) {
        if (res.data.status == 200) {
          tableData.arr = res.data.data.data;
          pageinfo['total'] = res.data.data.total;
        }
      });
    };

    getTabledata(); // 添加、编辑

    var openAdd = function openAdd() {
      router.push({
        path: '/black/blackRules'
      });
    };

    var openEdit = function openEdit(id) {
      router.push({
        path: '/black/blackRules',
        query: {
          id: id
        }
      });
    }; // 删除


    var arr = [];

    var delMore = function delMore() {
      arr = [];
      var ids = "";

      if (multipleSelection.value.length == 1) {
        ids = multipleSelection.value[0].id;
      } else {
        for (var i in multipleSelection.value) {
          arr.push(multipleSelection.value[i].id);
        }

        ids = arr.join(",");
      }

      delData(ids);
    };

    var delData = function delData(ids) {
      ElMessageBox.confirm('确认删除数据吗？', '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        proxy.$http.post('delblackfilterrule', {
          ids: ids
        }, {
          headers: {
            'token': sessionStorage.getItem("token")
          }
        }).then(function (res) {
          if (res.data.status == 200) {
            deldisabled.value = true;
            ElMessage({
              message: res.data.msg,
              type: 'success'
            });
            getTabledata();
          } else {
            ElMessage({
              message: res.data.msg,
              type: 'error'
            });
          }
        });
      }).catch(function () {});
    }; // 多选


    var multipleSelection = ref([]);

    var handleSelectionChange = function handleSelectionChange(val) {
      multipleSelection.value = val;

      if (multipleSelection.value.length > 0) {
        deldisabled.value = false;
      } else {
        deldisabled.value = true;
      }
    };

    return {
      pageinfo: pageinfo,
      multipleSelection: multipleSelection,
      handleSelectionChange: handleSelectionChange,
      deldisabled: deldisabled,
      delData: delData,
      delMore: delMore,
      getTabledata: getTabledata,
      tableData: tableData,
      openAdd: openAdd,
      openEdit: openEdit
    };
  }
};