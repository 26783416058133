import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.sort.js";
import zhCn from 'element-plus/lib/locale/lang/zh-cn';
import { reactive, ref, nextTick } from 'vue';
import { ElMessage, ElMessageBox } from 'element-plus';
import { getCurrentInstance } from 'vue';
export default {
  data: function data() {
    return {
      locale: zhCn,
      value1: "",
      size: 'small'
    };
  },
  setup: function setup() {
    var cztitle = ref();
    var formData = reactive({
      data: {
        title: "",
        pid: 0,
        node_ids: "",
        sort: ""
      }
    });
    var tableData = reactive({
      arr: []
    });
    var pageinfo = reactive({
      page: 1,
      pagesize: 10,
      total: ""
    });

    var _getCurrentInstance = getCurrentInstance(),
        proxy = _getCurrentInstance.proxy;

    var treeRef = ref(null);

    var getTabledata = function getTabledata() {
      proxy.$http.post('rolelist', {
        page: pageinfo.page,
        pagesize: pageinfo.pagesize
      }, {
        headers: {
          'token': sessionStorage.getItem("token")
        }
      }).then(function (res) {
        if (res.data.status == 200) {
          tableData.arr = res.data.data.data;
          pageinfo['total'] = res.data.data.total;
        } else {
          ElMessage({
            message: res.data.msg,
            type: 'error'
          });
        }
      });
    };

    getTabledata(); // 编辑用户

    var openEdit = function openEdit(id) {
      proxy.$http.post('rolepage', {
        id: id
      }, {
        headers: {
          'token': sessionStorage.getItem("token")
        }
      }).then(function (res) {
        if (res.data.status == 200) {
          cztitle.value = "编辑角色";
          addVisible.value = true;
          formData.data = res.data.data.data;
          var arr1 = res.data.data.data.node_ids.split(","); // var arr2=[]
          // for(var i in arr1){
          //    arr2.push(parseInt(arr1[i])) 
          // arr1=[22,23,28,21,12,13,14,16,17,29,19,20,30,25,24,26,27,10]
          // }

          formData.data.node_ids = arr1;
          var temp01 = [];
          var temp02 = [];

          for (var i in fatherNode.arr) {
            temp01[fatherNode.arr[i]] = true;
          }

          for (var k in arr1) {
            if (!temp01[arr1[k]]) {
              temp02.push(arr1[k]);
            }
          }

          nextTick(function () {
            treeRef.value.setCheckedKeys(temp02, true);
          });
        } else {
          ElMessage({
            message: res.data.msg,
            type: 'error'
          });
        }
      });
    }; // 节点选中
    // let getKeys=function(){
    // }
    // 添加用户


    var addVisible = ref(false);

    var openAdd = function openAdd() {
      formData.data = {
        title: "",
        pid: 0,
        node_ids: "",
        sort: ""
      };
      formData.data.node_ids = "";
      cztitle.value = "添加角色";
      addVisible.value = true;
    }; // 提交


    var toSumbit = function toSumbit() {
      // console.log(treeRef.value.getCheckedKeys())
      // console.log(treeRef.value.getHalfCheckedKeys())
      var arr = treeRef.value.getCheckedKeys().concat(treeRef.value.getHalfCheckedKeys()); // let arr=treeRef.value.getCheckedKeys(true)

      console.log(arr);
      var nodeids = arr.join(",");
      formData.data.node_ids = nodeids;

      if (cztitle.value == "添加角色") {
        proxy.$http.post('addrole', {
          title: formData.data.title,
          pid: formData.data.pid,
          node_ids: formData.data.node_ids,
          sort: formData.data.sort
        }, {
          headers: {
            'token': sessionStorage.getItem("token")
          }
        }).then(function (res) {
          if (res.data.status == 200) {
            addVisible.value = false;
            ElMessage({
              message: res.data.msg,
              type: 'success'
            });
            getTabledata();
          } else {
            ElMessage({
              message: res.data.msg,
              type: 'error'
            });
          }
        });
      } else if (cztitle.value == "编辑角色") {
        proxy.$http.post('updaterole', {
          id: formData.data.id,
          title: formData.data.title,
          pid: formData.data.pid,
          node_ids: formData.data.node_ids,
          sort: formData.data.sort
        }, {
          headers: {
            'token': sessionStorage.getItem("token")
          }
        }).then(function (res) {
          if (res.data.status == 200) {
            addVisible.value = false;
            ElMessage({
              message: res.data.msg,
              type: 'success'
            });
            getTabledata();
          } else {
            ElMessage({
              message: res.data.msg,
              type: 'error'
            });
          }
        });
      }
    }; // 权限


    var props = {
      label: 'title',
      children: 'children'
    };
    var nodedata = reactive({
      arr: []
    });
    var fatherNode = reactive({
      arr: []
    });

    var getNodelist = function getNodelist() {
      proxy.$http.post('nodelist', {}, {
        headers: {
          'token': sessionStorage.getItem("token")
        }
      }).then(function (res) {
        if (res.data.status == 200) {
          var data = res.data.data;

          for (var i in data) {
            fatherNode.arr.push(data[i].id);

            for (var j in data[i].children) {
              if (data[i].children[j].children) {
                fatherNode.arr.push(data[i].children[j].id);
              }
            }
          }

          nodedata.arr = res.data.data;
        }
      });
    };

    getNodelist(); // 多选

    var multipleSelection = ref([]);

    var handleSelectionChange = function handleSelectionChange(val) {
      multipleSelection.value = val;

      if (multipleSelection.value.length > 0) {
        deldisabled.value = false;
      } else {
        deldisabled.value = true;
      }
    }; // 删除


    var deldisabled = ref(true);
    var arr = [];

    var delMore = function delMore() {
      arr = [];
      var ids = "";

      if (multipleSelection.value.length == 1) {
        ids = multipleSelection.value[0].id;
      } else {
        for (var i in multipleSelection.value) {
          arr.push(multipleSelection.value[i].id);
        }

        ids = arr.join(",");
      }

      delData(ids);
    };

    var delData = function delData(ids) {
      ElMessageBox.confirm('确认删除角色吗？', '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        proxy.$http.post('delrole', {
          ids: ids
        }, {
          headers: {
            'token': sessionStorage.getItem("token")
          }
        }).then(function (res) {
          if (res.data.status == 200) {
            deldisabled.value = true;
            ElMessage({
              message: res.data.msg,
              type: 'success'
            });
            getTabledata();
          } else {
            ElMessage({
              message: res.data.msg,
              type: 'error'
            });
          }
        });
      }).catch(function () {});
    }; // 分页


    var handleSizeChange = function handleSizeChange(val) {
      pageinfo['pagesize'] = val;
      getTabledata();
    };

    var handleCurrentChange = function handleCurrentChange(val) {
      pageinfo['page'] = val;
      getTabledata();
    };

    return {
      getTabledata: getTabledata,
      tableData: tableData,
      multipleSelection: multipleSelection,
      handleSelectionChange: handleSelectionChange,
      pageinfo: pageinfo,
      cztitle: cztitle,
      formData: formData,
      toSumbit: toSumbit,
      props: props,
      nodedata: nodedata,
      getNodelist: getNodelist,
      treeRef: treeRef,
      addVisible: addVisible,
      openAdd: openAdd,
      openEdit: openEdit,
      deldisabled: deldisabled,
      delMore: delMore,
      delData: delData,
      handleSizeChange: handleSizeChange,
      handleCurrentChange: handleCurrentChange
    };
  }
};