import zhCn from 'element-plus/lib/locale/lang/zh-cn';
import { reactive, ref } from 'vue';
import { ElMessage } from 'element-plus';
import { getCurrentInstance } from 'vue';
export default {
  data: function data() {
    return {
      locale: zhCn,
      value1: "",
      size: 'small'
    };
  },
  setup: function setup() {
    var tableData = reactive({
      arr: []
    });
    var pageinfo = reactive({
      page: 1,
      pagesize: 10,
      total: ""
    });

    var _getCurrentInstance = getCurrentInstance(),
        proxy = _getCurrentInstance.proxy;

    var getTabledata = function getTabledata() {
      proxy.$http.post('orderlist', {
        page: pageinfo.page,
        pagesize: pageinfo.pagesize
      }, {
        headers: {
          'token': sessionStorage.getItem("token")
        }
      }).then(function (res) {
        if (res.data.status == 200) {
          tableData.arr = res.data.data.data;
          pageinfo['total'] = res.data.data.total;
        } else {
          ElMessage({
            message: res.data.msg,
            type: 'error'
          });
        }
      });
    };

    getTabledata(); // 编辑订单

    var formData = reactive({
      data: {
        id: "",
        status: "0"
      }
    });
    var addVisible = ref(false);

    var openEdit = function openEdit(id, status) {
      addVisible.value = true;
      formData.data.id = id;
      formData.data.status = status;
    };

    var toSumbit = function toSumbit() {
      proxy.$http.post('updateorder', {
        id: formData.data.id,
        status: formData.data.status
      }, {
        headers: {
          'token': sessionStorage.getItem("token")
        }
      }).then(function (res) {
        if (res.data.status == 200) {
          addVisible.value = false;
          ElMessage({
            message: res.data.msg,
            type: 'success'
          });
          getTabledata();
        } else {
          ElMessage({
            message: res.data.msg,
            type: 'error'
          });
        }
      });
    }; // 分页


    var handleSizeChange = function handleSizeChange(val) {
      pageinfo['pagesize'] = val;
      getTabledata();
    };

    var handleCurrentChange = function handleCurrentChange(val) {
      pageinfo['page'] = val;
      getTabledata();
    };

    return {
      formData: formData,
      addVisible: addVisible,
      toSumbit: toSumbit,
      openEdit: openEdit,
      getTabledata: getTabledata,
      tableData: tableData,
      pageinfo: pageinfo,
      handleSizeChange: handleSizeChange,
      handleCurrentChange: handleCurrentChange
    };
  }
};