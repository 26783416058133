import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import { useRouter } from "vue-router";
import { reactive, ref } from 'vue';
import { getCurrentInstance } from 'vue';
import { ElMessage } from 'element-plus';
import zhCn from 'element-plus/lib/locale/lang/zh-cn';
import data from '@/assets/region.json';
export default {
  data: function data() {
    return {
      locale: zhCn,
      size: "small"
    };
  },
  setup: function setup() {
    // console.log(data.data[0].children)
    var router = useRouter();
    var dqdrawer = ref(false);
    var teldrawer = ref(false);
    var hddrawer = ref(false);

    var _getCurrentInstance = getCurrentInstance(),
        proxy = _getCurrentInstance.proxy;

    var editid = router.currentRoute.value.query.id;
    var goodRluetableData = reactive({
      arr: []
    }); // 统计表格

    var formData = reactive({
      data: {
        title: "",
        intercept_set: {
          ip: "",
          called_prefix: "",
          caller_prefix: "",
          black_business_id: ""
        },
        rate_set: {
          rate_status: 1,
          limit_mode: "",
          rate_rule: [{
            limit_rate: "",
            limit_no: ""
          }]
        },
        blind_area_set: {
          blind_area_status: 1,
          blind_area_type: "",
          blind_area_region: ""
        },
        good_name_set: {
          good_name_status: 1,
          good_name_type: "",
          good_name_telephone_law_ids: ""
        },
        telephone_section_set: {
          telephone_section_status: 1,
          telephone_section_type: "",
          telephone_section: ""
        },
        telephone_b_w_set: {
          telephone_b_w_status: 1,
          telephone_b_type: "",
          telephone_w_type: "",
          telephone_b_w_limit_cycle: ""
        },
        call_phone_set: {
          called_phone_type: ""
        }
      }
    });
    var setNum = ref(true);
    goodRluetableData.arr = data.data[0].children;

    var cancelClick = function cancelClick() {
      dqdrawer.value = false;
      teldrawer.value = false;
      hddrawer.value = false;
    }; // 显示、隐藏


    var pcshow = ref(true);

    var switchPc = function switchPc(val) {
      if (val == 1) {
        pcshow.value = true;
      } else if (val == 2) {
        pcshow.value = false;
      }
    };

    var mqshow = ref(true);

    var switchMq = function switchMq(val) {
      if (val == 1) {
        mqshow.value = true;
      } else if (val == 2) {
        mqshow.value = false;
      }
    };

    var lhshow = ref(true);

    var switchLh = function switchLh(val) {
      if (val == 1) {
        lhshow.value = true;
      } else if (val == 2) {
        lhshow.value = false;
      }
    };

    var hdshow = ref(true);

    var switchHd = function switchHd(val) {
      if (val == 1) {
        hdshow.value = true;
      } else if (val == 2) {
        hdshow.value = false;
      }
    };

    var sykshow = ref(true);

    var switchSyk = function switchSyk(val) {
      if (val == 1) {
        sykshow.value = true;
      } else if (val == 2) {
        sykshow.value = false;
      }
    }; // 添加、编辑规则


    var toSaveRules = function toSaveRules() {
      if (editid) {
        proxy.$http.post('updateblackfilterrule', {
          id: editid,
          title: formData.data.title,
          intercept_set: formData.data.intercept_set,
          rate_set: formData.data.rate_set,
          blind_area_set: formData.data.blind_area_set,
          good_name_set: formData.data.good_name_set,
          telephone_section_set: formData.data.telephone_section_set,
          telephone_b_w_set: formData.data.telephone_b_w_set,
          call_phone_set: formData.data.telephone_b_w_set,
          admin_user_id: sessionStorage.getItem("id"),
          admin_user_api_id: sessionStorage.getItem("apiid")
        }, {
          headers: {
            'token': sessionStorage.getItem("token")
          }
        }).then(function (res) {
          if (res.data.status == 200) {
            ElMessage({
              message: res.data.msg,
              type: 'success'
            });
          } else {
            ElMessage({
              message: res.data.msg,
              type: 'error'
            });
          }
        });
      } else {
        proxy.$http.post('addblackfilterrule', {
          title: formData.data.title,
          intercept_set: formData.data.intercept_set,
          rate_set: formData.data.rate_set,
          blind_area_set: formData.data.blind_area_set,
          good_name_set: formData.data.good_name_set,
          telephone_section_set: formData.data.telephone_section_set,
          telephone_b_w_set: formData.data.telephone_b_w_set,
          call_phone_set: formData.data.telephone_b_w_set,
          admin_user_id: sessionStorage.getItem("id"),
          admin_user_api_id: sessionStorage.getItem("apiid")
        }, {
          headers: {
            'token': sessionStorage.getItem("token")
          }
        }).then(function (res) {
          if (res.data.status == 200) {
            ElMessage({
              message: res.data.msg,
              type: 'success'
            });
          } else {
            ElMessage({
              message: res.data.msg,
              type: 'error'
            });
          }
        });
      }
    }; // 行业列表


    var industrylist = reactive({
      data: []
    });

    var getIndustrylist = function getIndustrylist() {
      proxy.$http.post('industrylist', {
        pagesize: 500
      }, {
        headers: {
          'token': sessionStorage.getItem("token")
        }
      }).then(function (res) {
        if (res.data.status == 200) {
          industrylist.data = res.data.data.data;
        }
      });
    };

    getIndustrylist(); // 号段选择

    var telSectiontableData = reactive({
      arr: []
    });

    var telSectionList = function telSectionList() {
      proxy.$http.post('telephonesectionlist', {
        pagesize: 500
      }, {
        headers: {
          'token': sessionStorage.getItem("token")
        }
      }).then(function (res) {
        if (res.data.status == 200) {
          telSectiontableData.arr = res.data.data;
        }
      });
    };

    telSectionList();
    var checkedSelects = ref([]);
    var selectcheckAll = ref([]);

    var handleSelectCheckAllChange = function handleSelectCheckAllChange(index) {
      // console.log( selectcheckAll.value[index])
      if (selectcheckAll.value[index]) {
        selectcheckAll.value[index] = true;
        var arr = [];
        var arr2 = telSectiontableData.arr[index].children;

        for (var i in arr2) {
          arr.push(arr2[i]);
        }

        checkedSelects.value[index] = arr;
        console.log(selectcheckAll.value[index]);
      } else {
        selectcheckAll.value[index] = false;
        checkedSelects.value[index] = [];
      }
    };

    var handleCheckedSelectsChange = function handleCheckedSelectsChange(index, value) {
      checkedSelects.value[index] = value;
      var arr2 = telSectiontableData.arr[index].children;

      if (arr2.length == checkedTels.value[index].length) {
        selectcheckAll.value[index] = true;
      }
    };

    var confirmSectionClick = function confirmSectionClick() {
      var sections = "";

      for (var i in checkedSelects.value) {
        for (var j in checkedSelects.value[i]) {
          sections += checkedSelects.value[i][j] + ",";
        }
      }

      formData.data.telephone_section_set.telephone_section = sections;
      hddrawer.value = false;
    }; // 靓号选择


    var goodTeltableData = reactive({
      arr: []
    });

    var getgoodTelList = function getgoodTelList() {
      proxy.$http.post('telephonelawlist', {
        pagesize: 500
      }, {
        headers: {
          'token': sessionStorage.getItem("token")
        }
      }).then(function (res) {
        if (res.data.status == 200) {
          goodTeltableData.arr = res.data.data;
        }
      });
    };

    getgoodTelList();
    var checkedTels = ref([]);
    var telcheckAll = ref([]);

    var handleTelCheckAllChange = function handleTelCheckAllChange(index) {
      // console.log( telcheckAll.value[index])
      if (telcheckAll.value[index]) {
        telcheckAll.value[index] = true;
        var arr = [];
        var arr2 = goodTeltableData.arr[index].children;

        for (var i in arr2) {
          arr.push(arr2[i].id);
        }

        checkedTels.value[index] = arr;
      } else {
        telcheckAll.value[index] = false;
        checkedTels.value[index] = [];
      }

      console.log(checkedTels.value);
    };

    var handleCheckedTelsChange = function handleCheckedTelsChange(index, value) {
      checkedTels.value[index] = value;
      var arr2 = goodTeltableData.arr[index].children; // console.log("11111="+arr2.length)
      // console.log("11111="+checkedTels.value[index].length)

      if (arr2.length == checkedTels.value[index].length) {
        telcheckAll.value[index] = true;
      }
    };

    var confirmTelClick = function confirmTelClick() {
      var tels = "";

      for (var i in checkedTels.value) {
        for (var j in checkedTels.value[i]) {
          tels += checkedTels.value[i][j] + ",";
        }
      }

      formData.data.good_name_set.good_name_telephone_law_ids = tels;
      teldrawer.value = false;
    }; // 城市选择


    var isIndeterminate = ref();
    var checkedCities = ref([]);
    var checkAll = ref([]);
    var cityArea = reactive({
      data: []
    });
    cityArea.data = data.data[0].children;

    var handleCityCheckAllChange = function handleCityCheckAllChange(index) {
      // console.log("11111")
      // console.log(checkAll.value[index])
      if (checkAll.value[index]) {
        checkAll.value[index] = true;
        var arr = [];
        var arr2 = data.data[0].children[index].children;

        for (var i in arr2) {
          arr.push(arr2[i].title);
        }

        checkedCities.value[index] = arr;
      } else {
        checkAll.value[index] = false;
        checkedCities.value[index] = [];
      }

      console.log(checkedCities.value);
    };

    var handleCheckedCitiesChange = function handleCheckedCitiesChange(index, value) {
      checkedCities.value[index] = value;
      var arr2 = data.data[0].children[index].children;

      if (arr2.length == checkedCities.value[index].length) {
        checkAll.value[index] = true;
      }
    };

    var confirmCityClick = function confirmCityClick() {
      var citys = "";

      for (var i in checkedCities.value) {
        for (var j in checkedCities.value[i]) {
          citys += checkedCities.value[i][j] + ",";
        }
      } // console.log(citys)


      formData.data.blind_area_set.blind_area_region = citys;
      dqdrawer.value = false;
    }; // 添加频率规则


    var addNumRules = function addNumRules() {
      formData.data.rate_set.rate_rule.push({
        limit_rate: "",
        limit_no: ""
      });
    };

    var delNumRules = function delNumRules(index) {
      formData.data.rate_set.rate_rule.splice(index, 1);
    }; // 详情


    if (editid) {
      var getDetail = function getDetail() {
        proxy.$http.post('blackfilterrulepage', {
          id: editid
        }, {
          headers: {
            'token': sessionStorage.getItem("token")
          }
        }).then(function (res) {
          if (res.data.status == 200) {
            var data = res.data.data.data;
            formData.data.title = data.title;
            formData.data.intercept_set.called_prefix = data.called_prefix;
            formData.data.intercept_set.caller_prefix = data.caller_prefix;
            formData.data.intercept_set.ip = data.ip;
            formData.data.intercept_set.black_business_id = data.black_business_id;
            formData.data.rate_set.rate_status = data.rate_status;

            if (formData.data.rate_set.rate_status == 1) {
              pcshow.value = true;
            } else if (formData.data.rate_set.rate_status == 2) {
              pcshow.value = false;
            }

            formData.data.rate_set.limit_mode = data.limit_mode;
            formData.data.rate_set.rate_rule = JSON.parse(data.rate_set);
            formData.data.blind_area_set.blind_area_status = data.blind_area_status;
            formData.data.blind_area_set.blind_area_type = data.blind_area_type;

            if (formData.data.blind_area_set.blind_area_status == 1) {
              mqshow.value = true;
            } else if (formData.data.blind_area_set.blind_area_status == 2) {
              mqshow.value = false;
            }

            var blind_area = data.blind_area_region;
            formData.data.blind_area_set.blind_area_region = blind_area;

            if (blind_area) {
              var areaArr = [];
              areaArr = blind_area.split(',');
              var areaArr2 = [];

              for (var k = 0; k < 35; k++) {
                areaArr2[k] = new Array();
              }

              for (var ai in areaArr) {
                for (var aj in cityArea.data) {
                  for (var az in cityArea.data[aj].children) {
                    if (areaArr[ai] == cityArea.data[aj].children[az].title) {
                      areaArr2[aj][ai] = areaArr[ai];
                      checkedCities.value[aj] = areaArr2[aj];
                      var arr2 = cityArea.data[aj].children;

                      if (arr2.length == checkedCities.value[aj].length) {
                        checkAll.value[aj] = true;
                      }
                    }
                  }
                }
              }
            }

            formData.data.good_name_set.good_name_status = data.good_name_status;
            formData.data.good_name_set.good_name_type = data.good_name_type;

            if (formData.data.good_name_set.good_name_status == 1) {
              lhshow.value = true;
            } else if (formData.data.good_name_set.good_name_status == 2) {
              lhshow.value = false;
            }

            var good_name = data.good_name_telephone_law_ids;
            formData.data.good_name_set.good_name_telephone_law_ids = good_name;

            if (good_name) {
              var goodArr = [];
              goodArr = good_name.split(',');
              var goodArr2 = [];

              for (var gk = 0; gk < 30; gk++) {
                goodArr2[gk] = new Array();
              }

              for (var gi in goodArr) {
                for (var gj in goodTeltableData.arr) {
                  for (var gz in goodTeltableData.arr[gj].children) {
                    if (goodArr[gi] == goodTeltableData.arr[gj].children[gz].id) {
                      goodArr2[gj][gi] = parseInt(goodArr[gi]);
                      checkedTels.value[gj] = goodArr2[gj];
                      var garr2 = goodTeltableData.arr[gj].children;

                      if (garr2.length == checkedTels.value[gj].length) {
                        telcheckAll.value[gj] = true;
                      }
                    }
                  }
                }
              }
            }

            formData.data.telephone_section_set.telephone_section_status = data.telephone_section_status;

            if (formData.data.telephone_section_set.telephone_section_status == 1) {
              hdshow.value = true;
            } else if (formData.data.telephone_section_set.telephone_section_status == 2) {
              hdshow.value = false;
            }

            formData.data.telephone_section_set.telephone_section_type = data.telephone_section_type;
            var telephone_section = data.telephone_section;
            formData.data.telephone_section_set.telephone_section = telephone_section;

            if (telephone_section) {
              var sectionArr = [];
              sectionArr = telephone_section.split(',');
              var sectionArr2 = [];

              for (var sk = 0; sk < 4; sk++) {
                sectionArr2[sk] = new Array();
              }

              for (var si in sectionArr) {
                for (var sj in telSectiontableData.arr) {
                  for (var sz in telSectiontableData.arr[sj].children) {
                    if (sectionArr[si] == telSectiontableData.arr[sj].children[sz]) {
                      sectionArr2[sj][si] = sectionArr[si];
                      checkedSelects.value[sj] = sectionArr2[sj];
                      var sarr2 = telSectiontableData.arr[sj].children;

                      if (sarr2.length == checkedSelects.value[sj].length) {
                        selectcheckAll.value[sj] = true;
                      }
                    }
                  }
                }
              }
            }

            formData.data.telephone_b_w_set.telephone_b_w_status = data.telephone_b_w_status;

            if (formData.data.telephone_b_w_set.telephone_b_w_status == 1) {
              sykshow.value = true;
            } else if (formData.data.telephone_b_w_set.telephone_b_w_status == 2) {
              sykshow.value = false;
            }

            formData.data.telephone_b_w_set.telephone_b_type = data.telephone_b_type;
            formData.data.telephone_b_w_set.telephone_w_type = data.telephone_w_type;
            formData.data.telephone_b_w_set.telephone_b_w_limit_cycle = data.telephone_b_w_limit_cycle;
            formData.data.call_phone_set.called_phone_type = data.called_phone_type;
          }
        });
      };

      getDetail();
    }

    var toBack = function toBack() {
      router.push({
        path: '/black/rulesList'
      });
    };

    return {
      toSaveRules: toSaveRules,
      isIndeterminate: isIndeterminate,
      industrylist: industrylist,
      getIndustrylist: getIndustrylist,
      switchPc: switchPc,
      pcshow: pcshow,
      switchMq: switchMq,
      mqshow: mqshow,
      switchLh: switchLh,
      lhshow: lhshow,
      switchHd: switchHd,
      hdshow: hdshow,
      switchSyk: switchSyk,
      sykshow: sykshow,
      handleCityCheckAllChange: handleCityCheckAllChange,
      checkedCities: checkedCities,
      checkAll: checkAll,
      handleCheckedCitiesChange: handleCheckedCitiesChange,
      confirmCityClick: confirmCityClick,
      handleTelCheckAllChange: handleTelCheckAllChange,
      handleCheckedTelsChange: handleCheckedTelsChange,
      checkedTels: checkedTels,
      telcheckAll: telcheckAll,
      confirmTelClick: confirmTelClick,
      handleSelectCheckAllChange: handleSelectCheckAllChange,
      handleCheckedSelectsChange: handleCheckedSelectsChange,
      checkedSelects: checkedSelects,
      selectcheckAll: selectcheckAll,
      confirmSectionClick: confirmSectionClick,
      addNumRules: addNumRules,
      delNumRules: delNumRules,
      setNum: setNum,
      hddrawer: hddrawer,
      telSectiontableData: telSectiontableData,
      goodRluetableData: goodRluetableData,
      dqdrawer: dqdrawer,
      goodTeltableData: goodTeltableData,
      teldrawer: teldrawer,
      cancelClick: cancelClick,
      formData: formData,
      toBack: toBack
    };
  }
};