import { createRouter, createWebHistory } from 'vue-router'

import login from '../views/login.vue'
import Home from '../views/Home.vue'
import all from '../views/all.vue'

import apilist from '../views/index/apilist.vue'
import apidata from '../views/index/apidata.vue'
import apitest from '../views/index/apitest.vue'
import interfacelist from '../views/index/interfacelist.vue'
import apiadmin from '../views/index/apiadmin.vue'
import apirecharge from '../views/index/apirecharge.vue'
import rechargelist from '../views/finance/rechargelist.vue'
import person from '../views/account/person.vue'
import account from '../views/account/account.vue'
import nodelist from '../views/system/nodelist.vue'
import rolelist from '../views/system/rolelist.vue'
import industrylist from '../views/system/industrylist.vue'
import blacklist from '../views/system/blacklist.vue'
import goodtellist from '../views/system/goodtellist.vue'
import selectlist from '../views/system/selectlist.vue'
import linelist from '../views/line/linelist.vue'
import fplinelist from '../views/line/fplinelist.vue'

import baseset from '../views/system/baseset.vue'
import verify from '../components/verify.vue'

import black from '../views/black.vue'
import blackData from '../views/blackData/blackData.vue'
import blackStatis from '../views/blackData/blackStatis.vue'
import blackRules from '../views/blackData/blackRules.vue'
import blackip from '../views/blackData/blackip.vue'
import rulesList from '../views/blackData/rulesList.vue'
import reminderSun from '../views/blackData/reminderSun.vue'
import blackTel from '../views/blackData/blackTel.vue'
import vosDock from '../views/blackData/vosDock.vue'
import vosBlack from '../views/blackData/vosBlack.vue'
import apiDock from '../views/blackData/apiDock.vue'
import sipDock from '../views/blackData/sipDock.vue'
import blackTest from '../views/blackData/blackTest.vue'
import testRecord from '../views/blackData/testRecord.vue'

import cloud from '../views/cloud.vue'
import billsearch from '../views/cloudTel/billsearch.vue'
import creminderSun from '../views/cloudTel/reminderSun.vue'
import keyset from '../views/cloudTel/keyset.vue'
import monitorset from '../views/cloudTel/monitorset.vue'
import switchset from '../views/cloudTel/switchset.vue'
import telexport from '../views/cloudTel/telexport.vue'
import telmonitor from '../views/cloudTel/telmonitor.vue'
import telqueue from '../views/cloudTel/telqueue.vue'
import telsearch from '../views/cloudTel/telsearch.vue'
import teltext from '../views/cloudTel/teltext.vue'
import timesearch from '../views/cloudTel/timesearch.vue'
import ttssearch from '../views/cloudTel/ttssearch.vue'
import cloudData from '../views/cloudTel/cloudData.vue'
import synthesistxt from '../views/cloudTel/synthesistxt.vue'
import synthesis from '../views/cloudTel/synthesis.vue'
import cloudperson from '../views/cloudTel/person.vue'

import check from '../views/check.vue'
import checkData from '../views/telcheck/checkData.vue'
import checkLine from '../views/telcheck/checkLine.vue'
import checkRecord from '../views/telcheck/checkRecord.vue'
import checkTest from '../views/telcheck/checkTest.vue'

import call from '../views/call.vue'
import callData from '../views/call/checkData.vue'
import callRecord from '../views/call/callRecord.vue'



const routes = [
  {
    path: '/',
    redirect: {path: '/all' },
    name: 'Home',
    component: Home,
    children:[
      {path:'all',name:'all',component:all,redirect: {name:'apilist'},children:[
        {path:'apilist',name:'apilist',component:apilist,meta:{title:"我的API"}},
        {path:'apidata',name:'apidata',component:apidata},
        {path:'apitest',name:'apitest',component:apitest},
        {path:'interfacelist',name:'interfacelist',component:interfacelist,meta:{title:"接口管理"}},
        {path:'apiadmin',name:'apiadmin',component:apiadmin,meta:{title:"API管理"}},
        {path:'linelist',name:'linelist',component:linelist,meta:{title:"线路列表"}},
        {path:'fplinelist',name:'fplinelist',component:fplinelist,meta:{title:"分配列表"}},
        {path:'apirecharge',name:'apirecharge',component:apirecharge},
        {path:'rechargelist',name:'rechargelist',component:rechargelist,meta:{title:"消费明细"}},
        
        {path:'person',name:'person',component:person,meta:{title:"个人中心"}},
        {path:'account',name:'account',component:account,meta:{title:"账号管理"}},
        {path:'nodelist',name:'nodelist',component:nodelist,meta:{title:"节点管理"}},
        {path:'rolelist',name:'rolelist',component:rolelist,meta:{title:"角色管理"}},
        {path:'baseset',name:'baseset',component:baseset,meta:{title:"基础设置"}},
        {path:'industrylist',name:'industrylist',component:industrylist,meta:{title:"行业配置"}},
        {path:'blacklist',name:'blacklist',component:blacklist,meta:{title:"黑名单配置"}},
        {path:'goodtellist',name:'goodtellist',component:goodtellist,meta:{title:"靓号配置"}},
        {path:'selectlist',name:'selectlist',component:selectlist,meta:{title:"号段配置"}},
        {path:'verify',name:'verify',component:verify}
      ]},

      {path:'black',name:'black',component:black,redirect: {name:'blackData'},children:[
        {path:'blackData',name:'blackData',component:blackData,meta:{title:"数据统计"}},
        {path:'blackStatis',name:'blackStatis',component:blackStatis,meta:{title:"数据明细"}},
        {path:'blackRules',name:'blackRules',component:blackRules,meta:{title:"防御策略"}},
        {path:'rulesList',name:'rulesList',component:rulesList,meta:{title:"防御策略设置"}},
        {path:'blackip',name:'blackip',component:blackip,meta:{title:"IP白名单"}},
        {path:'blackTel',name:'blackTel',component:blackTel,meta:{title:"号码库管理"}},
        {path:'reminderSun',name:'reminderSun',component:reminderSun,meta:{title:"余额提醒"}},
        {path:'vosDock',name:'vosDock',component:vosDock,meta:{title:"VOS外部改写"}},
        {path:'vosBlack',name:'vosBlack',component:vosBlack,meta:{title:"VOS黑名单"}},
        {path:'apiDock',name:'apiDock',component:apiDock,meta:{title:"API对接"}},
        {path:'sipDock',name:'sipDock',component:sipDock,meta:{title:"SIP对接"}},
        {path:'blackTest',name:'blackTest',component:blackTest,meta:{title:"在线检测"}},
        {path:'testRecord',name:'testRecord',component:testRecord,meta:{title:"检测记录"}},
      ]},
      {path:'cloud',name:'cloud',component:cloud,redirect: {name:'cloudData'},children:[
        {path:'cloudData',name:'cloudData',component:cloudData,meta:{title:"数据统计"}},
        {path:'telexport',name:'telexport',component:telexport,meta:{title:"号码导入"}},
        {path:'telqueue',name:'telqueue',component:telqueue,meta:{title:"呼叫队列"}},
        {path:'creminderSun',name:'creminderSun',component:creminderSun,meta:{title:"余额提醒"}},
        {path:'synthesistxt',name:'synthesistxt',component:synthesistxt,meta:{title:"合成话术管理"}},
        {path:'synthesis',name:'synthesis',component:synthesis,meta:{title:"合成话术"}},
        {path:'teltext',name:'teltext',component:teltext,meta:{title:"变量话术管理"}},
        {path:'telmonitor',name:'telmonitor',component:telmonitor,meta:{title:"实时呼叫监控"}},
        {path:'billsearch',name:'billsearch',component:billsearch,meta:{title:"话单查询"}},
        {path:'timesearch',name:'timesearch',component:timesearch,meta:{title:"时段报表"}},
        {path:'telsearch',name:'telsearch',component:telsearch,meta:{title:"呼叫报表"}},
        {path:'ttssearch',name:'ttssearch',component:ttssearch,meta:{title:"TTS使用记录"}},
        {path:'keyset',name:'keyset',component:keyset,meta:{title:"按键配置"}},
        {path:'switchset',name:'switchset',component:switchset,meta:{title:"转接设置"}},
        {path:'cloudperson',name:'cloudperson',component:cloudperson,meta:{title:"个人中心"}},
        {path:'monitorset',name:'monitorset',component:monitorset,meta:{title:"监控配置"}},
      ]},
      {path:'check',name:'check',component:check,redirect: {name:'checkData'},children:[
        {path:'checkData',name:'checkData',component:checkData,meta:{title:"数据统计"}},
        {path:'checkLine',name:'checkLine',component:checkLine,meta:{title:"在线空号检测"}},
        {path:'checkTest',name:'checkTest',component:checkTest,meta:{title:"空号检测"}},
        {path:'checkRecord',name:'checkRecord',component:checkRecord,meta:{title:"检测记录"}},
      ]},
      {path:'call',name:'call',component:call,redirect: {name:'callData'},children:[
        {path:'callData',name:'callData',component:callData,meta:{title:"数据统计"}},
        {path:'callRecord',name:'callRecord',component:callRecord,meta:{title:"外呼记录"}},
      ]}
     
    ],
   
  },
  {path: '/login',name: 'login',component: login,meta:{title:"登录"}}
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from , next) => {
  const title = (to.meta.title ? to.meta.title : '') + '-飞象数据'
  document.title = title
  // 判断是否含有登录标识
  const isLogin = sessionStorage.getItem('token')
  console.log("isLogin="+isLogin)
  // 想要跳转到 person页面 但是没有标识 就next 去登录
    if(!isLogin){
      if(to.name=="login"){
        next()
      }else{
        router.push('login')
      }
    }else{
      next()
    }
})


export default router
